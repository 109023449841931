import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function PersonalInformation(props) {
  const { user } = props;
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    city: user?.address?.city || "",
    country: user?.address?.country || "",
    address: user?.address || "",
    twitterHandle: "",
    name: user?.name || "",
    username: user?.username || "",
    email: user?.email || "",
    phone: user?.phone || "",
    dob: user?.dob || "",
    aadharCardFilefront: null,
    panCardFile: null,
    aadharCardFileback: null,
    photoFile: null,
    city: user?.city || "",
    country: user?.country || "",
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleUpdateUser = () => {
    console.log("update user");
    const data = new FormData();
    data.append("name", formData.name);
    data.append("username", formData.username);
    data.append("email", formData.email);
    data.append("phone", formData.phone);
    data.append("address", formData.address);
    data.append("dob", formData.dob);
    data.append("aadharcardfront", formData.aadharCardFilefront);
    data.append("pancard", formData.panCardFile);
    data.append("aadharcardback", formData.aadharCardFileback);
    data.append("photo", formData.photoFile);
    data.append("city", formData.city);
    data.append("country", formData.country);
    const id = localStorage.getItem("id");
    axios
      .post(
        `${
          process.env.REACT_APP_BASE_URL
        }/user/updateuser/${localStorage.getItem("id")}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          alert("User updated successfully");
          navigate("/user/profile");
        } else {
          alert("User not updated");
        }
      });
  };

  return (
    <div className="flex flex-col gap-[34px] p-8">
      <div className="flex flex-col gap-2">
        <label htmlFor="city" className="text-[15px] font-light">
          City
        </label>
        <input
          type="text"
          name="city"
          placeholder="Enter your City"
          id="city"
          value={formData.city}
          onChange={handleChange}
          className="bg-[#222222] py-3 px-5 outline-none"
        />
      </div>
      <div className="flex flex-col gap-2">
        <label htmlFor="country" className="text-[15px] font-light">
          Country
        </label>
        <input
          type="text"
          name="country"
          placeholder="Enter your Country"
          id="country"
          value={formData.country}
          onChange={handleChange}
          className="bg-[#222222] py-3 px-5 outline-none"
        />
      </div>
      <div className="flex flex-col gap-2">
        <label htmlFor="address" className="text-[15px] font-light">
          Address
        </label>
        <input
          type="text"
          name="address"
          value={formData.address}
          placeholder="Enter your Address"
          id="address"
          onChange={handleChange}
          className="bg-[#222222] py-3 px-5 outline-none"
        />
      </div>
      <div className="flex flex-col gap-2">
        <label htmlFor="twitterHandle" className="text-[15px] font-light">
          Twitter or X Handle
        </label>
        <input
          type="text"
          name="twitterHandle"
          placeholder="Enter your Twitter or X Handle"
          id="twitterHandle"
          value={formData.twitterHandle}
          onChange={handleChange}
          className="bg-[#222222] py-3 px-5 outline-none"
        />
      </div>
      <div className="flex h-fit w-full justify-end">
        <button
          onClick={handleUpdateUser}
          className="bg-gd rounded-md px-9 py-2 "
        >
          Save
        </button>
      </div>
    </div>
  );
}
