import React from "react";
import Header from "./components/Header";
import Footer from "components/footer/FooterAuthDefault";
import FooterSection from "./sections/FooterSection";
import { FaPersonCircleQuestion } from "react-icons/fa6";
function PageNotFound() {
  return (
    <section className="text-white">
      <Header />
      <div className="flex min-h-[78vh] flex-col items-center  justify-center gap-2 text-white">
        <span className="text-[8rem]  ">
          <FaPersonCircleQuestion />
        </span>
        <span className="bold text-2xl">Page Not Found</span>
      </div>
      <FooterSection />
    </section>
  );
}

export default PageNotFound;
