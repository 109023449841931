/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import { Link } from "react-router-dom";

import adminroutes from "routes.js";
import Img from "./../../assets/img/landings/Vector.png";
import Form from "../../views/user/form/index";
const Sidebar = ({ open, onClose, role, user }) => {
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full w-64 flex-col  bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute top-4 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      {/* Logo */}
      <div className="mt-5 flex h-40 items-center justify-center">
        <Link to="/">
          <img src={Img} alt="logo" />
        </Link>
      </div>

      {/* <div class=" mb-7 h-px bg-gray-300 dark:bg-white/30" /> */}
      {/* Nav item */}

      <ul className="mb-auto pt-1">
        <Links role={role} routes={adminroutes} user={user} />
      </ul>

      {/* Free Horizon Card */}

      {/* Nav item end */}
      {/*create a logout button */}

      <div
        className="mt-10 flex w-full cursor-pointer items-center  justify-center gap-2"
        onClick={() => {
          localStorage.removeItem("token");
          localStorage.removeItem("id");
          window.location.href = "/";
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          fill="currentColor"
        >
          <path d="M10 9V5a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-6a2 2 0 0 1-2-2v-4h2v4h6V5h-6v4h-2zm-1.293 6.707L7.414 12l1.293-1.293L9.707 13H2v2h7.707l-1.293 1.293z" />
        </svg>

        <button className="  rounded-lg  text-white">Logout</button>
      </div>
    </div>
  );
};

export default Sidebar;
