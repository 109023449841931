import React, { useState, useEffect } from "react";
import { FaRegUser } from "react-icons/fa";
import { RiEditLine } from "react-icons/ri";
import { TfiLock } from "react-icons/tfi";
import { MdVerified } from "react-icons/md";
export default function SideBar({ active, setActive }) {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className=" w-full rounded-l-2xl pt-8   lg:w-[22rem] lg:border-r-[2px] lg:border-[#252525] lg:pt-12 ">
      <div className="border-b-[.5px] border-[#252525] pb-2 lg:px-12">
        <h1 className="w-full text-center text-[30px] font-medium lg:text-left">
          Profile
        </h1>
      </div>

      <ul className="my-8 flex h-full w-full pl-2  text-[16px] font-light lg:flex-col lg:gap-3 lg:pl-12">
        <div
          onClick={() => setActive("KYC")}
          className={`w-full ${
            active == "KYC"
              ? "bg-gradient-to-b from-[#F78DA7] to-[#9B51E0] pr-[3px]"
              : ""
          } ${
            isMobileView && active == "KYC"
              ? "bg-gradient-to-r from-[#F78DA7] to-[#9B51E0] !pr-0 pb-[2px] "
              : ""
          } `}
        >
          <li
            className={` flex h-full w-full cursor-pointer items-center justify-center gap-3 bg-[#171717] py-2 lg:min-w-[2.5rem]  lg:justify-start ${
              active == "Your Profile" ? " " : ""
            } ${isMobileView && active == "KYC" ? " " : ""} `}
          >
            {" "}
            <MdVerified />
            <span className="hidden lg:block">KYC</span>
          </li>
        </div>
        <div
          onClick={() => setActive("Your Profile")}
          className={`w-full ${
            active == "Your Profile"
              ? "bg-gradient-to-b from-[#F78DA7] to-[#9B51E0] pr-[3px]"
              : ""
          } ${
            isMobileView && active == "Your Profile"
              ? "bg-gradient-to-r from-[#F78DA7] to-[#9B51E0] !pr-0 pb-[2px] "
              : ""
          } `}
        >
          <li
            className={` flex h-full w-full cursor-pointer items-center justify-center gap-3 bg-[#171717] py-2 lg:min-w-[2.5rem]  lg:justify-start ${
              active == "Your Profile" ? " " : ""
            } ${isMobileView && active == "Your Profile" ? " " : ""} `}
          >
            {" "}
            <FaRegUser />
            <span className="hidden lg:block">Your Profile</span>
          </li>
        </div>

        <div
          onClick={() => setActive("Personal Information")}
          className={`w-full ${
            active == "Personal Information"
              ? "bg-gradient-to-b from-[#F78DA7] to-[#9B51E0] pr-[3px]"
              : ""
          } ${
            isMobileView && active == "Personal Information"
              ? "bg-gradient-to-r from-[#F78DA7] to-[#9B51E0] !pr-0 pb-[2px] "
              : ""
          } `}
        >
          <li
            className={` flex h-full w-full cursor-pointer items-center justify-center gap-3 bg-[#171717] py-2 lg:min-w-[2.5rem]  lg:justify-start `}
          >
            {" "}
            <RiEditLine />
            <span className="hidden lg:block">Personal Information</span>
          </li>
        </div>
        <div
          onClick={() => setActive("Security")}
          className={`w-full ${
            active == "Security"
              ? "bg-gradient-to-b from-[#F78DA7] to-[#9B51E0] pr-[3px]"
              : ""
          } ${
            isMobileView && active == "Security"
              ? "bg-gradient-to-r from-[#F78DA7] to-[#9B51E0] !pr-0 pb-[2px] "
              : ""
          } `}
        >
          <li
            className={` flex h-full w-full cursor-pointer items-center justify-center gap-3 bg-[#171717] py-2 lg:min-w-[2.5rem]  lg:justify-start `}
          >
            {" "}
            <TfiLock />
            <span className="hidden lg:block">Security</span>
          </li>
        </div>
      </ul>
    </div>
  );
}
