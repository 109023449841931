import AppLogo from "./sub-components/AppLogo";
// import ThemeControl from "./sub-components/ThemeControl";
import Logo from "./../../../assets/img/landings/Vector.png";
import Glowpink from "./../../../assets/img/landings/glowpink.png";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import "../../../assets/css/btn.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const links = ["FAQ", "CONTACT"];

export default function Header({ gd, onAboutUs, onPlans }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header
      data-aos="fade-down"
      className="dark:bg-neutral-800 relative sticky z-30 flex w-full flex-wrap py-4 text-sm sm:flex-nowrap sm:justify-start"
    >
      <nav
        className="mx-auto w-full max-w-[85rem] px-4 sm:flex sm:items-center sm:justify-between"
        aria-label="Global"
      >
        <div className="relative z-10 flex items-center justify-between">
          <Link to="/">
            <img src={Logo} alt="Logo" />
          </Link>
          <div className="sm:hidden">
            <button
              type="button"
              onClick={toggleMenu}
              className="hs-collapse-toggle dark:bg-transparent dark:border-neutral-700 inline-flex items-center justify-center gap-x-2 rounded-lg border border-gray-200  p-2 text-gray-800 shadow-sm hover:bg-gray-50 disabled:pointer-events-none disabled:opacity-50 dark:text-white dark:hover:bg-white/10"
              aria-controls="navbar-with-collapse"
              aria-label="Toggle navigation"
            >
              <svg
                className={`${
                  isMenuOpen ? "hidden" : "block"
                } size-4 flex-shrink-0`}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="3" x2="21" y1="6" y2="6" />
                <line x1="3" x2="21" y1="12" y2="12" />
                <line x1="3" x2="21" y1="18" y2="18" />
              </svg>
              <svg
                className={`${
                  isMenuOpen ? "block" : "hidden"
                } size-4 hidden flex-shrink-0`}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M18 6L6 18" />
                <path d="M6 6L18 18" />
              </svg>
            </button>
          </div>
        </div>
        <div className="hidden w-full sm:flex sm:items-center sm:justify-end">
          <div className="flex items-center gap-16 sm:flex-row">
            <Link
              className={`text-gd-hover ${
                onAboutUs
                  ? "text-gd font-medium"
                  : " text-white-600 hover:text-gray-400"
              } dark:text-neutral-400 dark:hover:text-neutral-500 font-medium`}
              to="/about-us"
            >
              About us
            </Link>
            {/* <Link
              className={`text-gd-hover ${
                onPlans
                  ? "text-gd font-medium"
                  : " text-white-600 hover:text-gray-400"
              } dark:text-neutral-400 dark:hover:text-neutral-500 font-medium`}
              to="/plans"
            >
              Our Plans
            </Link> */}
            <a
              className="text-white-600 text-gd-hover dark:text-neutral-400 dark:hover:text-neutral-500 cursor-pointer font-medium hover:text-gray-400"
              onClick={() => {
                navigate("/");
                handleClickScroll("contact-us");
              }}
            >
              Contact us
            </a>
            <Link to="/auth/user/login">
              <button
                type="button"
                className="rounded-md bg-gradient-to-r from-[#9b51e0] to-[#f78da7] px-5 py-2 text-sm font-semibold text-white hover:from-[#f78da7] hover:to-[#9b51e0]"
                style={{
                  background: `linear-gradient(to left, #9b51e0, #c655cc, #e162ba, #f076ad, #f78da7)`,
                  letterSpacing: "0.1em",
                }}
              >
                Login / Signup
              </button>
            </Link>
          </div>
        </div>
      </nav>
      {gd && (
        <img
          src={Glowpink}
          alt="Glowpink"
          className="nonselectable absolute"
          style={{
            width: "750px",
            height: "750px",
            position: "absolute",
            top: "-250px",
            zIndex: "-3",
            right: "0",
          }}
        />
      )}

      {/* Fullscreen overlay menu */}
      {isMenuOpen && (
        <div className="fixed inset-0 z-40  flex items-center justify-center bg-[#000001]">
          <button
            onClick={toggleMenu}
            className="absolute top-5 right-5 text-3xl text-white"
          >
            &times;
          </button>
          <div className="flex flex-col items-center gap-10">
            <Link
              className="text-xl font-medium text-white hover:text-gray-400"
              to="/about-us"
              onClick={toggleMenu}
            >
              About us
            </Link>
            <a
              className="text-xl font-medium text-white hover:text-gray-400"
              href="#"
              onClick={toggleMenu}
            >
              Contact us
            </a>

            <Link
              className={`text-gd-hover ${
                onPlans
                  ? "text-xl font-medium text-white hover:text-gray-400"
                  : " text-xl font-medium text-white hover:text-gray-400"
              } `}
              to="/plans"
            >
              Our Plans
            </Link>
            <a
              className="text-xl font-medium text-white hover:text-gray-400"
              onClick={() => {
                navigate("/");
                handleClickScroll("contact-us");
              }}
            >
              Contact us
            </a>
            <Link to="/auth/user/login" onClick={toggleMenu}>
              <button
                type="button"
                className="rounded-md bg-gradient-to-r from-[#9b51e0] to-[#f78da7] px-5 py-2 text-sm  text-white hover:from-[#f78da7] hover:to-[#9b51e0]"
                style={{
                  background: `linear-gradient(to left, #9b51e0, #c655cc, #e162ba, #f076ad, #f78da7)`,
                  letterSpacing: "0.1em",
                }}
              >
                Login / Signup
              </button>
            </Link>
          </div>
        </div>
      )}
    </header>
  );
}
