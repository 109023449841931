import React from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link } from "react-router-dom";
import navbarimage from "assets/img/layout/Navbar.png";
import { BsArrowBarUp } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import {
  IoMdNotificationsOutline,
  IoMdInformationCircleOutline,
} from "react-icons/io";
import avatar from "assets/img/avatars/admin.jpg";
import logout1 from "./Assests/Simplification.svg";
import noti from "./Assests/notification.svg";
import { MdCancel, MdVerified } from "react-icons/md";
import { FaClock } from "react-icons/fa6";
import { FaCheckCircle } from "react-icons/fa";
const Navbar = (props) => {
  const { onOpenSidenav, brandText, logout, user } = props;

  console.log(user);

  return (
    <nav className=" top-4 z-40 my-3 flex flex-row flex-wrap items-center justify-between rounded-xl  p-2  ">
      <span
        className="flexc bot relative cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
        onClick={onOpenSidenav}
      >
        <FiAlignJustify className="h-5 w-5" />
      </span>
      <div className="ml-[6px]">
        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p>
      </div>
      {/* <span
        className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
        onClick={onOpenSidenav}
      >
        <FiAlignJustify className="h-5 w-5" />
      </span> */}
      {/* <div className="relative mt-[3px] flex flex-grow  items-center  gap-2 rounded-full px-2  py-2 pr-4 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:flex-grow-0  md:gap-1 md:pr-0  xl:gap-2">
        <div className="flex h-10  w-full items-center  rounded-full  text-navy-700 dark:text-white  xl:w-[225px]">
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
          </p>
          <input
            type="text"
            placeholder="Search..."
            class="block h-full w-full rounded-full text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-[#111111] dark:text-white dark:placeholder:!text-white sm:w-fit"
          />
        </div> */}
      {/* <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span> */}
      {/* start Notification */}

      {/* start Horizon PRO */}

      {/* <div
          className="cursor-pointer text-gray-600"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove("dark");
              setDarkmode(false);
            } else {
              document.body.classList.add("dark");
              setDarkmode(true);
            }
          }}
        >
          {darkmode ? (
             <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
          ) : (
            <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
          )}
        </div> */}
      {/* Profile & Dropdown */}
      <Dropdown
        button={
          <div className="relative">
            <img
              className="w-10 rounded-full"
              src={user.photo || avatar}
              alt="Elon Musk"
            />
            <div className="absolute bottom-[-4px] right-0 w-fit rounded-full border-2 border-[#010101] bg-[#101010]">
              {user.kyc === "true" ? (
                <span title="KYC Verified" className="text-green-500">
                  {" "}
                  <FaCheckCircle />
                </span>
              ) : user.kyc === "false" ? (
                <span title="KYC not verified" className="text-red-500">
                  {" "}
                  <MdCancel />
                </span>
              ) : (
                <span title="KYC in review" className="text-yellow-500">
                  {" "}
                  <FaClock />
                </span>
              )}
            </div>
          </div>
        }
        children={
          <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
            {/* <div className="p-4">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    👋 Hey, {user
                              && user.username}
                    
                  </p>{" "}
                </div>
              </div>
              <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="flex flex-col p-4">
               
               
                <Link
                 
                  className=" text-sm font-medium text-red-500 hover:text-red-500 transition duration-150 ease-out hover:ease-in"
                  onClick={()=>{logout()}}
                >
                  Log Out
                </Link>
              </div> */}
            <div className="flex w-56 flex-col justify-start  overflow-hidden rounded-[20px]  ">
              <div className=" my-2 mx-6 flex gap-4  border-b border-[#4d4d4d] p-2 ">
                <img className="" src={noti} alt="" />
                Notifications
              </div>
              <div className="  mx-6 flex gap-4   p-2 ">
                <Link
                  className=" flex gap-4 text-sm font-medium  transition duration-150 ease-out  "
                  onClick={() => {
                    logout();
                  }}
                >
                  <img src={logout1} alt="" />
                  Log Out
                </Link>
              </div>
            </div>
          </div>
        }
        classNames={"py-2 top-8 -left-[180px] w-max"}
      />
      {/* </div> */}
    </nav>
  );
};

export default Navbar;
