import React, { useMemo } from "react";
import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { Link } from "react-router-dom";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import ProfitBook from "views/admin/userprofile/profitbooking/index";
import { rupeeWrapper } from "utils/utility";
import { formatToINR } from "utils/utility";

const InvestmentTable = (props) => {
  const { columnsData, tableData } = props;
  console.log(columnsData);
  console.log(tableData);

  const [showModal, setShowModal] = React.useState(false);
  const [ModalData, setModalData] = React.useState("");

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div className={"h-full w-full p-5"}>
      <div className="relative mt-5 flex items-center justify-between">
        <div className="text-3xl font-bold text-navy-700 dark:text-white">
          Investments
        </div>
      </div>

      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        <table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                    className="border-b border-gray-200 pr-14 pb-[10px] text-start dark:!border-navy-700"
                  >
                    <div className="text-xs font-bold tracking-wide text-gray-600">
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.length === 0 ? (
              <tr>
                <td colSpan={columns.length} className="text-center">
                  No data found
                </td>
              </tr>
            ) : (
              page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      let data;
                      if (
                        cell.column.Header === "COIN NAME" ||
                        cell.column.Header === "INVESTMENT AMOUNT" ||
                        cell.column.Header === "COIN PRICE" ||
                        cell.column.Header === "COIN QUANTITY" ||
                        cell.column.Header === "COIN CODE"
                      ) {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.column.Header === "INVESTMENT AMOUNT" ||
                            cell.column.Header === "COIN PRICE"
                              ? rupeeWrapper(formatToINR(cell.value))
                              : cell.value}
                          </p>
                        );
                      } else if (cell.column.Header === "DATE (MM/DD/YYYY)") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {new Date(cell.value).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            })}
                          </p>
                        );
                      } else if (cell.column.Header === "TRADE") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {showModal ? (
                              <>
                                <div
                                  className="fixed inset-0 z-50 flex w-full
              items-center justify-center overflow-y-auto 
            overflow-x-hidden rounded-lg bg-[#171717] bg-opacity-70 outline-none focus:outline-none"
                                >
                                  <div
                                    className="relative  my-6 mx-auto max-w-4xl"
                                    style={{
                                      width: "100%",
                                      borderRadius: "20px",
                                      border: "1px solid #404040",
                                      // background: "#252525 ",
                                      backdropFilter: "blur(25px)",
                                    }}
                                  >
                                    {/*content*/}
                                    <div className=" relative flex w-full flex-col p-10">
                                      {/*header*/}
                                      <div className="flex items-start justify-between rounded-t border-b border-solid pt-5 pb-5">
                                        <h3 className="text-3xl font-semibold">
                                          Add {ModalData && ModalData}
                                        </h3>
                                        <button
                                          className="text-[#F9F9F9]-500 outline-nonemr-1 mb-1 flex flex-row gap-2 px-6 py-2 text-sm font-bold uppercase transition-all duration-150 ease-linear"
                                          type="button"
                                          onClick={() => setShowModal(false)}
                                        >
                                          <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 100 100"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <line
                                              x1="10"
                                              y1="10"
                                              x2="90"
                                              y2="90"
                                              stroke="#F9F9F9"
                                              stroke-width="10"
                                            />
                                            <line
                                              x1="90"
                                              y1="10"
                                              x2="10"
                                              y2="90"
                                              stroke="#F9F9F9"
                                              stroke-width="10"
                                            />
                                          </svg>
                                        </button>
                                      </div>
                                      {/*body*/}
                                      <div className="relative ">
                                        {ModalData === "Profitbook" ? (
                                          <ProfitBook id={cell.value} />
                                        ) : null}
                                      </div>
                                      {/*footer*/}
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : null}
                            <Link
                              onClick={() => {
                                setShowModal(true);
                                setModalData("Profitbook");
                              }}
                            >
                              <button className="rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-600">
                                SELL
                              </button>
                            </Link>
                          </p>
                        );
                      }
                      return (
                        <td
                          className="pt-[14px] pb-[20px] sm:text-[14px]"
                          {...cell.getCellProps()}
                          key={index}
                        >
                          {data}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>

      <div className="mt-4 flex items-center justify-between">
        <FaArrowCircleLeft
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="h-6 w-6 cursor-pointer"
        />
        <FaArrowCircleRight
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="h-6 w-6 cursor-pointer"
        />
      </div>
    </div>
  );
};

export default InvestmentTable;
