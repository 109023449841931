import React, { useState, useEffect } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { PiCircleFill } from "react-icons/pi";

import { BsArrowLeftCircle } from "react-icons/bs";
import { BsArrowRightCircle } from "react-icons/bs";
import axios from "axios";
import { formatToINR } from "utils/utility";
import PayoutTable from "../components/PayoutTable";

export default function PayoutContainer() {
  const [payouts, setPayouts] = useState();
  const [totalPayouts, setTotalPayouts] = useState(0);

  const id = localStorage.getItem("id");

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [filter, setFilter] = useState("All Transaction");

  const [cellData, setCellData] = useState(payouts);

  console.log(id);

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 992);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + `/payout/user/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const data = res.data;
        setPayouts(data.payouts);
        setCellData(data.payouts);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setPayouts({});
        }
        console.log("Error while fetching,", err);
      });

    const handleResize = () => {
      setIsMobileView(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (payouts && payouts.length > 0) {
      let total = 0;
      payouts.forEach((element) => {
        total += element.amount;
      });
      setTotalPayouts(total);
    }
  }, [payouts]);

  const toggleFilterDropdown = () => {
    setIsFiltersOpen(!isFiltersOpen);
  };

  useEffect(() => {
    if (filter === "All Transactions") {
      setCellData(payouts);
    } else if (filter === "Deposit Only") {
      let filteredData = payouts.filter((item) => item.type === "RECEIVE");
      setCellData(filteredData);
    } else if (filter === "Payout Only") {
      let filteredData = payouts.filter((item) => item.type === "SEND");
      setCellData(filteredData);
    }
  }, [filter]);

  return (
    <div className="lg:px-18  !z-30 flex w-full flex-col items-center justify-start gap-4 overflow-x-hidden rounded-2xl border-[1px] border-[#ffffff09]  bg-[#ffffff] bg-opacity-[0.09] py-8 px-4 backdrop-blur-3xl lg:py-20 lg:px-16">
      <div className="flex w-full flex-col justify-between text-[#8C8C8C] lg:flex-row">
        <div className="flex flex-col">
          <h1 className="text-3xl font-medium">Payouts</h1>
          <h1 className="bold mt-2 text-5xl text-white">
            {payouts ? payouts.length : 0}
          </h1>
        </div>
        <div className="flex items-center justify-end gap-3">
          <div
            onClick={() => toggleFilterDropdown()}
            className="relative flex cursor-pointer items-center gap-1 rounded-lg border-[1px] border-[#71717120] p-3 text-[.7rem] lg:text-[14px]"
          >
            Filters
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="11"
              viewBox="0 0 12 11"
              fill="none"
            >
              <path
                d="M10.6663 5.49941H4.43319M2.23304 5.49941H1.33301M2.23304 5.49941C2.23304 5.19237 2.34892 4.8979 2.55517 4.68079C2.76143 4.46368 3.04117 4.3417 3.33286 4.3417C3.62455 4.3417 3.9043 4.46368 4.11055 4.68079C4.31681 4.8979 4.43268 5.19237 4.43268 5.49941C4.43268 5.80645 4.31681 6.10092 4.11055 6.31803C3.9043 6.53514 3.62455 6.65711 3.33286 6.65711C3.04117 6.65711 2.76143 6.53514 2.55517 6.31803C2.34892 6.10092 2.23304 5.80645 2.23304 5.49941ZM10.6663 9.0081H7.76645M7.76645 9.0081C7.76645 9.31522 7.6503 9.61002 7.44399 9.82718C7.23769 10.0443 6.95788 10.1663 6.66612 10.1663C6.37443 10.1663 6.09469 10.0438 5.88844 9.82673C5.68218 9.60961 5.5663 9.31515 5.5663 9.0081M7.76645 9.0081C7.76645 8.70099 7.6503 8.40672 7.44399 8.18956C7.23769 7.9724 6.95788 7.8504 6.66612 7.8504C6.37443 7.8504 6.09469 7.97237 5.88844 8.18948C5.68218 8.4066 5.5663 8.70106 5.5663 9.0081M5.5663 9.0081H1.33301M10.6663 1.99071H9.09985M6.89971 1.99071H1.33301M6.89971 1.99071C6.89971 1.68367 7.01558 1.3892 7.22184 1.17209C7.4281 0.95498 7.70784 0.833008 7.99953 0.833008C8.14396 0.833008 8.28698 0.862953 8.42041 0.921133C8.55385 0.979313 8.67509 1.06459 8.77722 1.17209C8.87935 1.27959 8.96036 1.40722 9.01563 1.54768C9.0709 1.68814 9.09935 1.83868 9.09935 1.99071C9.09935 2.14274 9.0709 2.29329 9.01563 2.43375C8.96036 2.57421 8.87935 2.70183 8.77722 2.80933C8.67509 2.91684 8.55385 3.00211 8.42041 3.06029C8.28698 3.11847 8.14396 3.14842 7.99953 3.14842C7.70784 3.14842 7.4281 3.02645 7.22184 2.80933C7.01558 2.59222 6.89971 2.29776 6.89971 1.99071Z"
                stroke="#8C8C8C"
                stroke-width="1.2"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
            </svg>
            <ul
              className={`absolute top-[100%] flex w-fit flex-col gap-2 whitespace-nowrap   p-1  ${
                isFiltersOpen ? "block" : "hidden"
              }`}
            >
              <li
                onClick={() => setFilter("All Payouts")}
                className="inline w-fit border-b-[.5px] border-b-[#a4a4a420] "
              >
                All Payouts
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="z-40 mb-4 flex w-full flex-col justify-start gap-2">
        <div className="flex items-center gap-2">
          <PiCircleFill className="text-green-500" />
          Total Payouts :
          <span
            className={totalPayouts <= 0 ? "text-green-500" : "text-red-500"}
          >
            ₹{formatToINR(totalPayouts)}
          </span>
        </div>
        {/* <div className="flex items-center gap-2">
          <PiCircleFill className="text-red-500" />
          Total Payouts :<span className={"text-red-500"}>{totalPayouts}</span>
        </div> */}
      </div>

      <hr className="w-full border-[1px] border-[#525252]" />
      <div
        className={`${isMobileView ? "w-screen overflow-x-auto px-14" : ""}`}
      >
        {cellData && cellData.length > 0 && (
          <PayoutTable
            columnData={[
              {
                Header: "UTR/REFERENCE",
                accessor: "reference",
              },
              {
                Header: "Type",
                accessor: "type",
              },
              {
                Header: "Payment",
                accessor: "PaymentMethod",
              },
              {
                Header: "Amount",
                accessor: "amount",
              },

              {
                Header: "Date & Time",
                accessor: "date",
              },
            ]}
            cellData={cellData}
          />
        )}
      </div>
    </div>
  );
}
