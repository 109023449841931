import React from "react";

function index() {
  return (
    <div className="flex w-full justify-center text-white">
      <div data-tf-live="01JGPAFQKFQGJ8Q6JFVB2V139Z"></div>
      {/* <div data-tf-live="01JGC527CJ8C3ZTR6KFJK2WZ2K"></div> */}
      {/* <script src="//embed.typeform.com/next/embed.js"></script> */}
    </div>
  );
}

export default index;
