import React from "react";
import SlotSheet from "views/admin/userprofile/userdetail/components/SlotSheet";

const index = () => {
  const id = localStorage.getItem("id");
  return (
    <div className="my-8 border-t border-[#212121] py-4">
      <SlotSheet userId={id} isAdmin={false} />
    </div>
  );
};

export default index;
