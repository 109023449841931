import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { LuView } from "react-icons/lu";
import { MdVerified, MdCancel } from "react-icons/md";
import { FaClock } from "react-icons/fa";
import KYCPreviewSlider from "./Sub-Components/KYCslider";
import { handleFileUpload } from "../../../../utils/helpersUploader";

const Kyc = ({ user }) => {
  const [documents, setDocuments] = useState({
    aadharFront: user.aadharcardfront || "",
    aadharBack: user.aadharcardback || "",
    panCard: user.pancard || "",
  });
  const [documentTypes, setDocumentTypes] = useState({
    aadharFront: "",
    aadharBack: "",
    panCard: "",
  });
  const [kycStatus, setKycStatus] = useState(user.kyc || "false");
  const [isUploading, setIsUploading] = useState(false);

  const id = localStorage.getItem("id");
  const token = localStorage.getItem("token");

  const handleFileChange = async (event, fieldName) => {
    const file = event.target.files[0];
    const result = await handleFileUpload(file, setIsUploading);

    if (result) {
      setDocuments((prev) => ({
        ...prev,
        [fieldName]: result.url,
      }));

      setDocumentTypes((prev) => ({
        ...prev,
        [fieldName]: result.type,
      }));
    }
  };

  const handleSave = async () => {
    const kycData = {
      aadharcardfront: documents.aadharFront,
      aadharcardback: documents.aadharBack,
      pancard: documents.panCard,
      documentTypes: documentTypes,
      kyc: "waiting",
    };

    const datatosend = { ...user, ...kycData };

    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/user/updateuser/${id}`,
        datatosend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("KYC details updated successfully.");
      setKycStatus("waiting");
    } catch (err) {
      console.error("Error saving KYC details:", err);
      toast.error("Failed to save KYC details. Please try again.");
    }
  };

  const renderDocumentPreview = (url, type, altText) => {
    if (!url) return null;

    return (
      <div className="flex items-center justify-center">
        <img
          src={url}
          alt={altText}
          className="h-32 w-32 rounded-md object-cover"
        />
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-[34px] p-8">
      <h2 className="text-xl font-bold">KYC Section</h2>

      {kycStatus === "true" && (
        <div className="flex items-center gap-2 font-semibold text-green-500">
          <MdVerified /> <span>KYC Verified</span>
        </div>
      )}

      {kycStatus === "false" && (
        <div className="flex items-center justify-center gap-2 font-semibold text-red-500">
          <MdCancel />
          <span>KYC Not Verified</span>
        </div>
      )}

      {kycStatus === "true" && (
        <KYCPreviewSlider
          kycStatus={kycStatus}
          aadharBack={documents.aadharBack}
          aadharFront={documents.aadharFront}
          panCard={documents.panCard}
          documentTypes={documentTypes}
        />
      )}

      {kycStatus === "waiting" && (
        <div className="flex flex-col items-center justify-center">
          <div className="mb-3 text-[48px] text-gray-500">
            <FaClock />
          </div>
          <div className="flex flex-col items-center justify-center text-center font-semibold text-gray-500">
            Your KYC is under review.
          </div>
        </div>
      )}

      {kycStatus === "false" && (
        <div>
          {/* Aadhar Card Front */}
          <div className="mb-4 flex gap-2 rounded-md border-2 border-dotted border-[#323232] bg-[#262424] p-4">
            <div className="flex w-full flex-col gap-2">
              <label htmlFor="aadharFront" className="text-[15px] font-light">
                Aadhar Card (Front) - Images (JPEG, PNG) or PDF
              </label>
              <input
                type="file"
                accept="image/jpeg,image/png,image/jpg,application/pdf"
                onChange={(e) => handleFileChange(e, "aadharFront")}
                className="file:cursor-pointer file:rounded-md file:border-0 file:bg-gradient-to-r file:from-[#F78DA7] file:to-[#9B51E0] file:px-4 file:py-2 file:text-white file:focus:outline-none"
              />
            </div>
            {documents.aadharFront &&
              renderDocumentPreview(
                documents.aadharFront,
                documentTypes.aadharFront,
                "Aadhar Front"
              )}
          </div>

          {/* Aadhar Card Back */}
          <div className="mb-4 flex gap-2 rounded-md border-2 border-dotted border-[#323232] bg-[#262424] p-4">
            <div className="flex w-full flex-col gap-2">
              <label htmlFor="aadharBack" className="text-[15px] font-light">
                Aadhar Card (Back) - Images (JPEG, PNG) or PDF
              </label>
              <input
                type="file"
                accept="image/jpeg,image/png,image/jpg,application/pdf"
                onChange={(e) => handleFileChange(e, "aadharBack")}
                className="file:cursor-pointer file:rounded-md file:border-0 file:bg-gradient-to-r file:from-[#F78DA7] file:to-[#9B51E0] file:px-4 file:py-2 file:text-white file:focus:outline-none"
              />
            </div>
            {documents.aadharBack &&
              renderDocumentPreview(
                documents.aadharBack,
                documentTypes.aadharBack,
                "Aadhar Back"
              )}
          </div>

          {/* PAN Card */}
          <div className="mb-4 flex gap-2 rounded-md border-2 border-dotted border-[#323232] bg-[#262424] p-4">
            <div className="flex w-full flex-col gap-2">
              <label htmlFor="panCard" className="text-[15px] font-light">
                PAN Card - Images (JPEG, PNG) or PDF
              </label>
              <input
                type="file"
                accept="image/jpeg,image/png,image/jpg,application/pdf"
                onChange={(e) => handleFileChange(e, "panCard")}
                className="file:cursor-pointer file:rounded-md file:border-0 file:bg-gradient-to-r file:from-[#F78DA7] file:to-[#9B51E0] file:px-4 file:py-2 file:text-white file:focus:outline-none"
              />
            </div>
            {documents.panCard &&
              renderDocumentPreview(
                documents.panCard,
                documentTypes.panCard,
                "PAN Card"
              )}
          </div>

          {/* Save Button */}
          <div className="flex h-fit w-full justify-end">
            <button
              onClick={handleSave}
              className={`rounded-md bg-gradient-to-r from-[#F78DA7] to-[#9B51E0] px-9 py-2 text-white ${
                isUploading ? "opacity-70" : ""
              }`}
              disabled={isUploading}
            >
              {isUploading ? "Uploading..." : "Save"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Kyc;
