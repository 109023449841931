import React from "react";
import { TbTriangleFilled, TbTriangleInvertedFilled } from "react-icons/tb";
import { GoTriangleDown } from "react-icons/go";
import { PiArrowCircleUpLeftLight } from "react-icons/pi";
import { PiArrowCircleDownRightLight } from "react-icons/pi";
import deposit from "../Assests/Deposit.svg";
import payout from "../Assests/Payout.svg";
import { BsInfoCircle } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import { PiCircleFill } from "react-icons/pi";
import { BsArrowLeftCircle } from "react-icons/bs";
import { BsArrowRightCircle } from "react-icons/bs";
import { formatToINR } from "utils/utility";
export default function Table({ columnData, cellData }) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const tableStyle = {
    width: "100%",
  };

  const cellStyle = {
    padding: "8px",
  };

  return (
    <>
      <table style={tableStyle} className="!rounded-xl text-[14px]">
        <thead className="rounded-xl text-left">
          <tr>
            {columnData.map((data, index) => (
              <th
                className="truncate px-4 font-extralight text-cyan-300 lg:!px-10"
                key={index}
                style={cellStyle}
              >
                {data.Header}
              </th>
            ))}
          </tr>
        </thead>

        <tbody className="w-full rounded-b-xl pb-5 text-left">
          {cellData.length < 1 ? (
            <tr className="!my-6 w-full">
              <td className="!w-full text-center" colSpan={columnData.length}>
                <h1>No Transaction</h1>
              </td>
            </tr>
          ) : (
            cellData.map((item, index) => (
              <tr key={index} className="!my-6">
                <td style={cellStyle} className="text-left">
                  <span>
                    {item.accountDetails
                      ? item.accountDetails
                      : "Not Available"}
                  </span>
                </td>
                <td
                  style={cellStyle}
                  className="max-w-[150px] truncate text-left"
                >
                  <span>
                    {item.paymentProof ? (
                      <a
                        target="_blank"
                        className="ml-[55px] text-brandLinear underline"
                        href={item.paymentProof}
                      >
                        Link
                      </a>
                    ) : (
                      <span className="ml-8">Not Available</span>
                    )}{" "}
                  </span>
                </td>

                <td style={cellStyle} className="p-auto text-left">
                  {item.type === "RECEIVE" ? (
                    <img
                      src={deposit}
                      alt="Deposit"
                      className="mx-auto h-6 w-6"
                    />
                  ) : (
                    <img
                      src={payout}
                      alt="Payout"
                      className="mx-auto h-6 w-6"
                    />
                  )}
                </td>
                <td style={cellStyle} className="text-left text-center">
                  <span>{item.PaymentMethod}</span>
                </td>
                <td
                  style={cellStyle}
                  className={`text-left text-center ${
                    item.type === "RECEIVE" ? "text-green-500" : "text-red-500"
                  }`}
                >
                  {item.type === "SEND" ? "-" : "+"} ₹{formatToINR(item.amount)}
                </td>
                <td style={cellStyle} className="text-left text-center">
                  {item.sentTo ? item.sentTo : "NA"}
                </td>
                <td style={cellStyle} className="text-left">
                  <div className="mx-auto w-full">
                    {new Date(item.date).toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}
                    ,{" "}
                    <span className="text-[#8C8C8C]">
                      {
                        //  new Date(item.date).toLocaleDateString("en-US", {
                        //   day: "numeric",
                        //   month: "short",
                        //   year: "numeric",
                        // })
                        //time only
                        new Date(item.date).toLocaleTimeString("en-US", {
                          hour: "numeric",
                          minute: "numeric",

                          hour12: true,
                        })
                      }
                    </span>
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <div className="mt-4 flex w-full items-center justify-center gap-4 px-16">
        <BsArrowLeftCircle className="text-[30px] opacity-40" />
        <div className="flex items-center justify-center gap-4 text-[#404040]">
          <span className="bg-gd rounded-full px-3 py-1 text-white">1</span>
          <span className="rounded-full p-2">2</span>
          <span className="rounded-full p-2">3</span>
          <span className="rounded-full p-2">4</span>
          <span className="rounded-full p-2">5</span>
          <span className="rounded-full p-2">6</span>
          <span className="rounded-full p-2">7</span>
          <span className="rounded-full p-2">8</span>
          <span className="rounded-full p-2">9</span>
        </div>
        <BsArrowRightCircle className="text-[30px]" />
      </div>
    </>
  );
}
