import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useParams,
  Link,
} from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar/index.jsx";
import Footer from "components/footer/Footer";
import routes from "routes.js";
import InputField from "components/fields/InputField";
import axios from "axios";
import Banner from "views/admin/marketplace/components/Banner";
import { toast } from "react-toastify";

export default function Admin(props) {
  const { ...rest } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState("Add Investment");
  const [user, setUser] = React.useState([]);
  const [success, setSuccess] = React.useState(false);
  const [failed, setFailed] = React.useState(false);
  const { id } = useParams();
  const [message, setMessage] = React.useState("");
  const [cryptoData, setCryptoData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [investment, setInvestment] = useState({
    investamount: "",
    investtype: "BUY",
    coinname: "",
    investdate: "",
    coincode: "",
    coinprice: "",
    coinquantity: "",
  });

  React.useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + `/user/getuser/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        const res = response.data.filter((user) => user._id === id);

        setUser(res[0]);
      });
    const fetchData = async () => {
      axios
        .get(process.env.REACT_APP_BASE_URL + `/api/list`)
        .then((response) => {
          const data = response.data.data;
          //sort data by name
          data.sort((a, b) => a.name.localeCompare(b.name));
          setCryptoData(data);
          console.log("investment data", data);
        });
      setLoading(false);
    };

    fetchData();
  }, [id]);

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };

  const handleCreateInvestment = () => {
    const investmentData = {
      investamount: investment.investamount,
      investtype: investment.investtype,
      coinname: investment.coinname,
      investdate: investment.investdate,
      coincode: computedCoinCode,
      coinprice: investment.coinprice,
      coinquantity: computedCoinQuantity,
    };
    console.log(investmentData);
    //check if all fields are filled
    if (
      investmentData.investamount === "" ||
      investmentData.coinname === "" ||
      investmentData.investdate === "" ||
      investmentData.coinprice === "" ||
      investmentData.coinquantity === ""
    ) {
      setFailed(true);
      setMessage("Please fill all fields");
      setTimeout(() => {
        setFailed(false);
      }, 2000);
    } else {
      axios
        .post(
          process.env.REACT_APP_BASE_URL + `/investment/addinvestment/${id}`,
          investmentData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          },
          investmentData
        )
        .then((response) => {
          if (response.data) {
            // setSuccess(true);
            toast.success("Investment created successfully!");
            // setMessage(response.data);
            // setTimeout(() => {
            //   setSuccess(false);
            // }, 3000);
          }
        })
        .catch((err) => {
          // setFailed(true);
          toast.error("Investment Failed Try Again");
          // setTimeout(() => {
          // setFailed(false);
          // }, 3000);
        });
    }
  };
  const computedCoinCode = cryptoData?.filter(
    (item) => item.name === investment.coinname
  )[0]?.symbol;

  // Compute coinquantity
  const computedCoinQuantity =
    investment.investamount && investment.coinprice
      ? investment.investamount / investment.coinprice
      : "";

  return (
    <>
      {loading ? (
        <div class="flex h-full h-screen w-full items-center justify-center bg-lightPrimary dark:!bg-navy-900">
          {" "}
          <dotlottie-player
            src="https://lottie.host/42efddd6-e327-4839-88ba-098a0a6ef3f8/sg3Bewaizz.json"
            background="transparent"
            speed="1"
            style={{
              height: "300px",
              width: "300px",
            }}
            loop
            autoplay
          ></dotlottie-player>
        </div>
      ) : (
        <>
          {" "}
          {failed && (
            <div class="absolute  bottom-10 mb-10 ml-10 start-0">
              <div
                class="max-w-xs rounded-xl border border-gray-200 bg-white shadow-lg dark:border-gray-700 dark:bg-gray-800"
                role="alert"
              >
                <div class="flex p-4">
                  <div class="flex-shrink-0">
                    <svg
                      class="size-4 mt-0.5 flex-shrink-0 text-red-500"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 0a8 8 0 0 0 0 16A8 8 0 0 0 8 0zM7 5a1 1 0 0 1 2 0v5a1 1 0 1 1-2 0V5zm1 7a1 1 0 0 1-2 0 1 1 0 0 1 2 0z"
                      />
                    </svg>
                  </div>
                  <div class="ms-3">
                    <p class="text-sm text-gray-700 dark:text-gray-400">
                      {message}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {success && (
            <div class="absolute  bottom-0 mb-10 ml-10 start-0">
              <div
                class="max-w-xs rounded-xl border border-gray-200 bg-white shadow-lg dark:border-gray-700 dark:bg-gray-800"
                role="alert"
              >
                <div class="flex p-4">
                  <div class="flex-shrink-0">
                    <svg
                      class="size-4 mt-0.5 flex-shrink-0 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 0a8 8 0 0 0 0 16A8 8 0 0 0 8 0zM7 5a1 1 0 0 1 2 0v5a1 1 0 1 1-2 0V5zm1 7a1 1 0 0 1-2 0 1 1 0 0 1 2 0z"
                      />
                    </svg>
                  </div>
                  <div class="ms-3">
                    <p class="text-sm text-gray-700 dark:text-gray-400">
                      {message}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className=" h-full w-full">
            {/* Navbar & Main Content */}
            <div className="h-full w-full ">
              {/* Main Content */}
              <div class="mt-7 grid w-full grid-cols-1 gap-7 md:grid-cols-1 lg:grid-cols-2">
                {/* <div>
                    <p class="text-base mb-3 font-medium leading-none text-white-800">
                      Name
                    </p>
                    <InputField
                      class="w-full p-3 mt-4 border border-white-300 rounded outline-none focus:bg-white-50"
                      value={`${user.name}`}
                      disabled
                    />
                   
                  </div>
                  <div>
                    <p class="text-base mb-3 font-medium leading-none text-white-800">
                      Email
                    </p>
                    <InputField
                      class="w-full mt-3 border border-white-300 rounded outline-none focus:bg-white-50"
                      value={`${user.email}`}
                      disabled
                    />
                   
                  </div> */}
                <div>
                  <p class="text-white-800 mb-3 text-base font-medium leading-none">
                    Total Balance
                  </p>
                  <InputField
                    class="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
                    value={`${user.balance}`}
                    disabled
                  />
                </div>

                <div>
                  <p class="text-white-800 text-base font-medium leading-none">
                    Invested Coin
                  </p>
                  <select
                    id="countries"
                    required
                    class="mt-11 flex h-12 w-full items-center justify-center rounded-xl  border bg-white/0 p-3 text-sm outline-none"
                    onChange={(e) => {
                      setInvestment({
                        ...investment,
                        coinname: e.target.value,
                      });
                    }}
                  >
                    {cryptoData &&
                      cryptoData.map((item) => {
                        return (
                          <option
                            className="bg-white/0 text-sm
                                      text-navy-700 dark:text-white
                                      "
                            style={{
                              backgroundColor: "#",
                              margin: "0px",
                              padding: "100px",
                            }}
                            value={item.name}
                            key={item.id}
                          >
                            {item.name} ( {item.symbol} )
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div>
                  <p class="text-white-800 mb-3  text-base font-medium leading-none">
                    Invested Amount
                  </p>
                  <InputField
                    class="border-white-300 focus:bg-white-50 w-full rounded border py-4 outline-none"
                    type="number"
                    onChange={(e) => {
                      setInvestment({
                        ...investment,
                        investamount: e.target.value,
                      });
                    }}
                    required
                  />
                </div>
                <div>
                  <p class="text-white-800 mb-3 text-base font-medium leading-none">
                    Investment Date
                  </p>
                  <InputField
                    class="border-white-300  focus:bg-white-50 mt-4 w-full rounded border outline-none"
                    type="datetime-local"
                    required
                    onChange={(e) => {
                      setInvestment({
                        ...investment,
                        investdate: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  <p class="text-white-800 mb-3 text-base font-medium leading-none">
                    Investment Type
                  </p>
                  <InputField
                    class="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
                    type="text"
                    value={investment.investtype}
                    disabled
                  />
                </div>
                <div>
                  <p class="text-white-800  mb-3 text-base font-medium leading-none">
                    Investment Coin Code
                  </p>
                  <InputField
                    class="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
                    type="text"
                    value={computedCoinCode || ""}
                    onChange={(e) => {
                      setInvestment({
                        ...investment,
                        coincode: e.target.value,
                      });
                    }}
                    disabled
                  />
                </div>
                <div>
                  <p class="text-white-800 text-base font-medium leading-none">
                    Coin Price at Investment
                  </p>
                  <InputField
                    class="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
                    required
                    type="number"
                    onChange={(e) => {
                      setInvestment({
                        ...investment,
                        coinprice: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  <p class="text-white-800 text-base font-medium leading-none">
                    No. of coins received
                  </p>
                  <InputField
                    class="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
                    value={computedCoinQuantity}
                    disabled
                  />
                </div>
              </div>
              <div class="mt-7 flex items-center justify-end">
                {/* <button
                    // class="w-1/2 py-4 text-white font-medium rounded bg-gradient-to-r from-cyan-400 to-lightBlue-500"
                    class="bg-indigo-700 rounded hover:bg-indigo-600 transform duration-300 ease-in-out text-sm font-medium px-6 py-4 text-white lg:max-w-[174px] w-full"

                    
                  >
                    Add Investment
                  </button> */}

                <button
                  className=" bg-gd mr-1 mb-1 rounded px-6 py-3 text-sm  font-bold text-white outline-none transition-all duration-150 ease-linear focus:outline-none"
                  type="button"
                  // onClick={() => handleAddUser()}
                  onClick={handleCreateInvestment}
                >
                  Create Investment
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
