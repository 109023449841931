import Navbar from "components/navbar/RTL";
import Header from "./components/Header";
import FooterSection from "./sections/FooterSection";
import glowCyan from "./Assets/glowCyan.png";
import glowpink from "../../assets/img/landings/glowpink.png";

export default function TermsAndConditions() {
  return (
    <>
      <div className="relative">
        <img
          src={glowCyan}
          draggable="false"
          className="nonselectable glowMovingTL absolute left-[-600px] z-0 !z-0 h-[1000px] w-[1350px]"
        />
        <img
          src={glowpink}
          draggable="false"
          className="nonselectable glowMovingTL absolute right-[-650px] top-12 z-0 !z-0 h-[1000px] w-[1350px]"
        />
        <div className="z-50 mt-2 text-white">
          <Header />
        </div>

        <div className="!z-50 min-h-screen w-full py-16 px-4 text-white md:px-8">
          <div className="mx-auto max-w-4xl">
            <h1 className="text-gd mb-2 text-center text-4xl font-bold md:text-5xl">
              <span className="text-gd font-bold">Terms and Conditions</span>
            </h1>
            <p className="mb-12 text-center text-gray-400">
              Last updated: January 17, 2025
            </p>

            <section className="mb-12">
              <h2 className="text-gd mb-6 text-2xl font-semibold">
                1. Eligibility
              </h2>
              <div className="space-y-4">
                <p className="leading-relaxed text-gray-300">
                  All investors must be at least 18 years of age and legally
                  eligible to enter into an agreement. A minimum investment of
                  $35,999 is required to participate in portfolio management
                  services. Investments acquired through loans, borrowed funds,
                  or credit facilities will not be accepted.
                </p>
              </div>
            </section>

            <section className="mb-12">
              <h2 className="text-gd mb-6 text-2xl font-semibold">
                2. Documentation
              </h2>
              <div className="space-y-4">
                <div>
                  <h3 className="mb-2 text-lg font-medium">
                    2.1 Required Documents
                  </h3>
                  <p className="leading-relaxed text-gray-300">
                    Clients must provide the following mandatory documents
                    during onboarding:
                  </p>
                  <ul className="mt-2 list-inside list-disc space-y-2 text-gray-300">
                    <li>
                      AADHAR Card (or equivalent identification for non-Indian
                      clients)
                    </li>
                    <li>PAN Card (for Indian clients)</li>
                  </ul>
                </div>
                <div>
                  <h3 className="mb-2 text-lg font-medium">
                    2.2 Document Verification
                  </h3>
                  <p className="leading-relaxed text-gray-300">
                    The provided documents must be valid and authentic.
                    TITANTECH INVESTMENTS reserves the right to verify the
                    documents.
                  </p>
                </div>
              </div>
            </section>

            <section className="mb-12">
              <h2 className="text-gd mb-6 text-2xl font-semibold">
                3. Investment Management
              </h2>
              <div className="space-y-4">
                <p className="leading-relaxed text-gray-300">
                  TITANTECH INVESTMENTS employs diversified cryptocurrency
                  strategies to optimize portfolio returns. Monthly returns
                  range between 4% to 20%, depending on individual agreements.
                  We do not guarantee profits, and past performance is not
                  indicative of future results.
                </p>
                <p className="leading-relaxed text-gray-300">
                  Clients agree to allow TITANTECH INVESTMENTS full discretion
                  in managing their portfolios, including buying, selling, and
                  reallocating cryptocurrency assets.
                </p>
              </div>
            </section>

            <section className="mb-12">
              <h2 className="text-gd mb-6 text-2xl font-semibold">
                4. Payments and Payouts
              </h2>
              <div className="space-y-4">
                <p className="leading-relaxed text-gray-300">
                  Monthly returns will be provided to clients as per the agreed
                  percentage and detailed in their performance reports. All
                  payments will be subject to applicable taxes, including TDS as
                  per government regulations (e.g., 194S as per Form-26QE).
                </p>
                <p className="leading-relaxed text-gray-300">
                  Clients are responsible for providing accurate bank or crypto
                  wallet details for payouts. TITANTECH INVESTMENTS reserves the
                  right to delay or adjust payouts in case of market volatility
                  or technical issues.
                </p>
              </div>
            </section>

            <section className="mb-12">
              <h2 className="text-gd mb-6 text-2xl font-semibold">
                5. Risk Disclosure
              </h2>
              <div className="mb-6 rounded-lg border  !border-[#ffffff3b] bg-white/[8%] p-6">
                <p className="mb-4 leading-relaxed text-gray-300">
                  Investments in cryptocurrency involve a high level of risk and
                  may result in significant financial loss. Please consider:
                </p>
                <ul className="list-inside list-disc space-y-2 text-gray-300">
                  <li>
                    TITANTECH INVESTMENTS is not liable for market losses or
                    fluctuations
                  </li>
                  <li>
                    Clients should invest only what they can afford to lose
                  </li>
                  <li>Cryptocurrency investments carry inherent risks</li>
                  <li>
                    Market conditions can significantly impact investment
                    performance
                  </li>
                </ul>
              </div>
            </section>

            <section className="mb-12">
              <h2 className="text-gd mb-6 text-2xl font-semibold">
                6. Confidentiality
              </h2>
              <div className="space-y-4">
                <p className="leading-relaxed text-gray-300">
                  Client information, including personal and financial data,
                  will be treated as confidential and stored securely. TITANTECH
                  INVESTMENTS will not share client information with third
                  parties, except as required by law or to fulfill service
                  obligations.
                </p>
              </div>
            </section>

            <section className="mb-12">
              <h2 className="text-gd mb-6 text-2xl font-semibold">
                7. Contact Information
              </h2>
              <div className="space-y-4">
                <p className="leading-relaxed text-gray-300">
                  For questions or concerns regarding these Terms and
                  Conditions, please contact us:
                </p>
                <ul className="list-inside list-disc space-y-2 text-gray-300">
                  <li>Email: help@titantechnologies.in</li>
                  <li>Phone: +91-9329104725</li>
                  <li>Website: titantechnologies.in</li>
                </ul>
              </div>
            </section>
          </div>
        </div>
      </div>
      <hr className="border-t border-gray-800" />
      <FooterSection />
    </>
  );
}
