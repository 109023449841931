import React, { useState } from "react";
import SideBar from "../Components/SideBar";
import YourProfile from "../Components/YourProfile";
import PersonalInformation from "../Components/PersonalInformation";
import Security from "../Components/Security";
import { IoMdClose } from "react-icons/io";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Kyc from "../Components/Kyc";
export default function Profile() {
  const navigate = useNavigate();
  const [active, setActive] = useState("KYC");
  const [user, setUser] = useState([]);
  const [profit, setProfit] = useState([]);
  // const [investamount, setInvestAmount] = useState([]);
  const [currentinvestment, setCurrentInvestment] = useState([]);
  useEffect(() => {
    document.title = "Profile Overview | User";
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("id");
    Promise.all([
      axios.get(process.env.REACT_APP_BASE_URL + "/user/getuser", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
      axios.get(process.env.REACT_APP_BASE_URL + "/investment/getprofitbook", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
      axios.get(
        process.env.REACT_APP_BASE_URL + "/investment/getcurrentinvestment",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      ),
    ])
      .then(([userRes, Profitres, currentinvestmentRes]) => {
        const user = userRes.data.filter((item) => item._id === id);
        setUser(user[0]);
        console.log(user);
        const profit = Profitres.data.filter((item) => item.uid === id);
        setProfit(profit);
        const currentinvestment = currentinvestmentRes.data.filter(
          (item) => item.uid === id
        );
        setCurrentInvestment(currentinvestment);
        console.log(currentinvestment);
        // setInvestAmount(currentinvestment[0].investamount);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const editProfile = (user) => {};

  return (
    <div className="mt-16 flex min-h-fit w-full flex-col rounded-2xl bg-[#171717] text-white lg:flex-row">
      <SideBar active={active} setActive={setActive} />
      <div className="mb-8 h-full w-full  lg:pt-12">
        <div className="hidden w-full justify-end border-b-[.5px] border-[#252525] pr-12 pb-4 lg:flex  ">
          <button
            onClick={() => {
              navigate("/user/dashboard");
            }}
            className="h-[36px]"
          >
            <IoMdClose className="hidden text-[30px] text-[#717171] lg:block" />
          </button>
        </div>
        <div className="lg:px-12">
          {active === "Personal Information" && (
            <PersonalInformation editProfile={editProfile(user)} user={user} />
          )}
          {active === "Security" && (
            <Security user={user} editProfile={editProfile(user)} />
          )}
          {active === "Your Profile" && <YourProfile user={user} />}
          {active === "KYC" && <Kyc user={user} />}
        </div>
      </div>
    </div>
  );
}
