import React, { useState, useEffect } from "react";
import { SlOptionsVertical } from "react-icons/sl";
import axios from "axios";

import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Vector from "./../../../../assets/img/avatars/avatar1.png";
import InputField from "components/fields/InputField"; // Make sure this path is correct
import Card from "components/card"; // Make sure this path is correct
import { Link } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";
import { formatToINR } from "utils/utility";
import { toast } from "react-toastify";

export default function UserTable({ columnData, cellData }) {
  const [data, setData] = useState(cellData);

  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [search, setSearch] = useState();
  const [clientOnly, setClientOnly] = useState(false);
  const itemsPerPage = 5; // Set the number of items per page

  useEffect(() => {
    setData(cellData);
  }, [cellData]);

  const handleAddUser = () => {
    if (password === confirmPassword) {
      const client = true;
      const user = {
        name,
        username,
        email,
        phone,
        password,
        client,
      };
      axios
        .post(process.env.REACT_APP_BASE_URL + "/auth/user/register", user)
        .then((response) => {
          if (response.status === 200) {
            toast.success("User added successfully");
            setShowModal(false);
            setData([...data, user]);
          }
        })
        .catch((error) => {
          toast.error(error.response.data);
        });
    } else {
      alert("Passwords do not match");
    }
  };

  const handleUserDelete = async (id) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + "/investment/getcurrentinvestment",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 200) {
        const investments = response.data;
        const userInvestments = investments.filter((inv) => inv.uid === id);

        const confirmed = window.confirm(
          userInvestments.length > 0
            ? "User has ongoing investments. Are you sure you want to delete this user?"
            : "Are you sure you want to delete this user?"
        );

        if (confirmed) {
          await deleteUser(id);
        }
      }
    } catch (error) {
      alert(error.response.data);
    }
  };

  const deleteUser = async (id) => {
    const deleteRequest = async () => {
      const response = await axios.delete(
        process.env.REACT_APP_BASE_URL + "/user/deleteuser/" + id,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 200) {
        setData(data.filter((user) => user._id !== id));
        return "User deleted successfully";
      }
    };

    toast.promise(deleteRequest(), {
      pending: "Deleting user...",
      success: "User deleted successfully 👌",
      error: {
        render({ data }) {
          return data.response?.data || "An error occurred";
        },
      },
    });
  };

  const toggleDropdown = (id) => {
    setActiveDropdown(activeDropdown === id ? null : id);
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const nextPage = () => {
    if (currentPage < Math.ceil(data.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    searchUser(); // Call searchUser whenever 'search' changes
  }, [search]);

  const searchUser = () => {
    setCurrentPage(1);
    if (!search || search.trim() === "") {
      setData(cellData); // Reset data if search is empty
      return;
    }

    const query = search.trim().toLowerCase();

    const filteredData = cellData.filter((user) => {
      if (!isNaN(query)) {
        // If query is numeric, search by phone number
        return user.phone.includes(query);
      } else if (query.includes("@")) {
        // If query contains '@', search by email
        return user.email.toLowerCase().includes(query);
      } else {
        // Otherwise, search by name
        return user.name.toLowerCase().includes(query);
      }
    });

    setData(filteredData);
  };
  const filterClientOnly = () => {
    setClientOnly(!clientOnly);
    if (clientOnly) {
      const filteredData = cellData.filter((user) => {
        return user.client === true;
      });
      console.log("filter", filteredData);
      setData(filteredData);
    } else {
      setData(cellData);
    }
  };
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [filter, setFilter] = useState("All Users");
  const toggleFilterDropdown = () => {
    setIsFiltersOpen(!isFiltersOpen);
  };

  useEffect(() => {
    if (filter === "All Users") {
      setCurrentPage(1);
      setData(cellData);
    } else if (filter === "Clients Only") {
      setCurrentPage(1);
      const filteredData = cellData.filter((user) => {
        return user.client === true;
      });
      setData(filteredData);
    } else if (filter === "Except Clients") {
      setCurrentPage(1);
      const filteredData = cellData.filter((user) => {
        return user.client !== true;
      });
    } else if (filter === "Kyc Pending") {
      setCurrentPage(1);
      const filteredData = cellData.filter((user) => {
        return user.kyc === "waiting";
      });
      setData(filteredData);
    }
  }, [filter]);

  return (
    <div className={"mt-8 h-full w-full p-4 sm:overflow-x-auto"}>
      <div className="relative flex items-center justify-between">
        <div className="text-2xl font-bold text-navy-700 dark:text-white">
          User Details
        </div>
        <div className="flex items-center justify-end gap-3 ">
          <div className="mb-2 flex items-center justify-center gap-1 !text-white">
            {/* <input
              className=" scale-[140%] !text-white"
              type="checkbox"
              value={clientOnly}
              onClick={filterClientOnly}
            />
            <span className="text-white">Client only</span> */}
            <div
              onClick={() => toggleFilterDropdown()}
              className="relative flex cursor-pointer items-center gap-1 rounded-lg border-[1px] border-[#71717120] p-3 text-[.7rem] lg:text-[14px]"
            >
              Filter By : {filter}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="11"
                viewBox="0 0 12 11"
                fill="none"
              >
                <path
                  d="M10.6663 5.49941H4.43319M2.23304 5.49941H1.33301M2.23304 5.49941C2.23304 5.19237 2.34892 4.8979 2.55517 4.68079C2.76143 4.46368 3.04117 4.3417 3.33286 4.3417C3.62455 4.3417 3.9043 4.46368 4.11055 4.68079C4.31681 4.8979 4.43268 5.19237 4.43268 5.49941C4.43268 5.80645 4.31681 6.10092 4.11055 6.31803C3.9043 6.53514 3.62455 6.65711 3.33286 6.65711C3.04117 6.65711 2.76143 6.53514 2.55517 6.31803C2.34892 6.10092 2.23304 5.80645 2.23304 5.49941ZM10.6663 9.0081H7.76645M7.76645 9.0081C7.76645 9.31522 7.6503 9.61002 7.44399 9.82718C7.23769 10.0443 6.95788 10.1663 6.66612 10.1663C6.37443 10.1663 6.09469 10.0438 5.88844 9.82673C5.68218 9.60961 5.5663 9.31515 5.5663 9.0081M7.76645 9.0081C7.76645 8.70099 7.6503 8.40672 7.44399 8.18956C7.23769 7.9724 6.95788 7.8504 6.66612 7.8504C6.37443 7.8504 6.09469 7.97237 5.88844 8.18948C5.68218 8.4066 5.5663 8.70106 5.5663 9.0081M5.5663 9.0081H1.33301M10.6663 1.99071H9.09985M6.89971 1.99071H1.33301M6.89971 1.99071C6.89971 1.68367 7.01558 1.3892 7.22184 1.17209C7.4281 0.95498 7.70784 0.833008 7.99953 0.833008C8.14396 0.833008 8.28698 0.862953 8.42041 0.921133C8.55385 0.979313 8.67509 1.06459 8.77722 1.17209C8.87935 1.27959 8.96036 1.40722 9.01563 1.54768C9.0709 1.68814 9.09935 1.83868 9.09935 1.99071C9.09935 2.14274 9.0709 2.29329 9.01563 2.43375C8.96036 2.57421 8.87935 2.70183 8.77722 2.80933C8.67509 2.91684 8.55385 3.00211 8.42041 3.06029C8.28698 3.11847 8.14396 3.14842 7.99953 3.14842C7.70784 3.14842 7.4281 3.02645 7.22184 2.80933C7.01558 2.59222 6.89971 2.29776 6.89971 1.99071Z"
                  stroke="#8C8C8C"
                  stroke-width="1.2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
              </svg>
              <ul
                className={`absolute top-[100%] flex w-fit flex-col gap-2 whitespace-nowrap   p-1  ${
                  isFiltersOpen ? "block" : "hidden"
                }`}
              >
                <li
                  onClick={() => setFilter("Clients Only")}
                  className="inline w-fit border-b-[.5px] border-b-[#a4a4a420] hover:underline "
                >
                  Clients Only
                </li>
                <li
                  onClick={() => setFilter("Except Clients")}
                  className="inline w-fit border-b-[.5px] border-b-[#a4a4a420] hover:underline "
                >
                  Except Clients
                </li>
                <li
                  onClick={() => setFilter("All Users")}
                  className="inline  w-fit border-b-[.5px] border-b-[#a4a4a420] hover:underline "
                >
                  All Users
                </li>
                <li
                  onClick={() => setFilter("Kyc Pending")}
                  className="inline  w-fit border-b-[.5px] border-b-[#a4a4a420] hover:underline "
                >
                  KYC Pending
                </li>
              </ul>
            </div>
          </div>

          <button
            type="button"
            onClick={() => setShowModal(true)}
            className="bg-gd hover:bg-gd 4 mb-2 flex 
           items-center justify-center rounded-lg px-2.5 py-1.5 text-sm font-medium text-white me-2 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <IoMdAdd className="mr-1 text-[16px]" />
            Add User
          </button>
        </div>
        {showModal && (
          <>
            <div
              className="fixed inset-0 z-50 flex w-full items-center justify-center
               overflow-y-auto overflow-x-hidden
            rounded-lg  bg-opacity-70  outline-none focus:outline-none"
            >
              <div
                className="relative my-6 mx-auto max-w-4xl bg-[#252525] text-white"
                style={{
                  width: "100%",
                  borderRadius: "20px",
                  border: "1px solid #404040",
                  // background: "#252525",
                  backdropFilter: "blur(25px)",
                }}
              >
                {/*content*/}
                <div className=" relative flex w-full flex-col p-10">
                  {/*header*/}
                  <div className="flex items-start justify-between rounded-t border-b border-solid pt-5 pb-5">
                    <h3 className="flex gap-1 text-3xl font-semibold">
                      Add User
                    </h3>
                  </div>
                  {/*body*/}
                  <div className="relative ">
                    <div
                      className="mt-7 grid w-full grid-cols-1 gap-7 md:grid-cols-1 lg:grid-cols-2"
                      style={{
                        color: "#ACACAC",
                      }}
                    >
                      <div>
                        <p className="text-base font-medium leading-none ">
                          Full Name
                        </p>

                        <InputField
                          className="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div>
                        <p className="text-white-800 text-base font-medium leading-none">
                          Username
                        </p>
                        <InputField
                          className="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                      <div>
                        <p className="text-white-800 text-base font-medium leading-none">
                          email
                        </p>
                        <InputField
                          className="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>

                      <div>
                        <p className="text-white-800 text-base font-medium leading-none">
                          Phone Number
                        </p>
                        <InputField
                          className="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                      <div>
                        <p className="text-white-800  text-base font-medium leading-none">
                          Password
                        </p>
                        <InputField
                          className="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
                          type="password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <div>
                        <p className="text-white-800  text-base font-medium leading-none">
                          Confirm Password
                        </p>
                        <InputField
                          className="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
                          type="password"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  {/*footer*/}
                  <div className="mt-10 flex items-center justify-between rounded-b">
                    <button
                      className="text-[#F9F9F9]-500 outline-nonemr-1 mb-1 flex flex-row gap-2 px-6 py-2 text-sm font-bold uppercase transition-all duration-150 ease-linear"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <line
                          x1="10"
                          y1="10"
                          x2="90"
                          y2="90"
                          stroke="#F9F9F9"
                          strokeWidth="10"
                        />
                        <line
                          x1="90"
                          y1="10"
                          x2="10"
                          y2="90"
                          stroke="#F9F9F9"
                          strokeWidth="10"
                        />
                      </svg>{" "}
                      Cancel
                    </button>
                    <button
                      className="bg-gd  active:bg-emerald-600 mr-1 mb-1 rounded px-6 py-3 text-sm font-bold uppercase text-white shadow outline-none transition-all duration-150 ease-linear hover:shadow-lg"
                      type="button"
                      onClick={() => handleAddUser()}
                    >
                      Create User
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div>
        <InputField
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search user.."
        />
      </div>

      <table className="my-6 w-full rounded-xl text-[17px]">
        <thead className="rounded-xl">
          <tr>
            {columnData.map((data, index) => (
              <th
                className="h-[72px] truncate px-4 py-2 text-left font-semibold text-white lg:px-10"
                key={index}
              >
                {data.Header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="w-full rounded-b-xl pb-5">
          {currentItems.length < 1 ? (
            <tr className="my-6 w-full">
              <td className="w-full text-center" colSpan={columnData.length}>
                <h1>No Transaction</h1>
              </td>
            </tr>
          ) : (
            currentItems.map((item, index) => (
              <tr key={index} className="mb-6">
                <td className="h-[72px] px-4 py-2 text-white">
                  <div className="flex gap-1">
                    <img
                      src={item.userImg ? item.userImg : Vector}
                      className="h-9 w-9 rounded-full object-cover object-center"
                      alt=""
                    />
                    <div className="flex flex-col gap-1">
                      <h1 className="text-[10px] font-bold text-white">
                        {item.username}
                      </h1>
                      <p className="text-[10px] font-medium text-[#ACACAC]">
                        {item.email}
                      </p>
                    </div>
                  </div>
                </td>
                <td className="px-4 py-2 text-center text-xs text-white">
                  <span>{item.phone}</span>
                </td>
                <td className="flex items-center justify-center px-4 py-2 text-center text-xs text-white">
                  <div
                    className={`w-[90px] rounded-md border-[.8px] py-2 ${
                      !item.phone
                        ? "border-[#E0042C] bg-[#E0042C]/10 text-[#E0042C]"
                        : "border-[#2DC24E] bg-[#2DC24E]/10 text-[#2DC24E]"
                    }`}
                  >
                    {!item.phone ? "Inactive" : " Active"}
                  </div>
                </td>
                <td className="px-4 py-2 text-center text-xs text-white">
                  <span>₹{formatToINR(item.totalInvestment)}</span>
                </td>
                <td className="flex justify-center gap-1 px-4 py-2 text-xs text-white">
                  <div className="flex gap-1">
                    <span>
                      {new Date(item.JoinDate).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })}
                    </span>
                    <span className="text-[#8C8C8C]">
                      {new Date(item.JoinDate).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </span>
                  </div>
                </td>
                <td className="px-4 py-2 text-center text-white">
                  <div className="relative mx-auto flex justify-center text-center">
                    <button onClick={() => toggleDropdown(item._id)}>
                      <SlOptionsVertical />
                    </button>
                    {activeDropdown === item._id && (
                      <div className="absolute top-8 right-0 z-10 mt-2 w-32 rounded-md bg-[#171717] py-2 text-white shadow-xl">
                        <button
                          onClick={() => handleUserDelete(item._id)}
                          className="block w-full px-4 py-2 text-left text-sm hover:bg-gray-200"
                        >
                          Delete
                        </button>
                        <Link to={`/admin/userdetail/${item._id}`} key={index}>
                          <button className="block w-full px-4 py-2  text-left text-sm hover:bg-gray-200">
                            View Profile
                          </button>
                        </Link>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      {/* Pagination Controls */}
      <div className="mt-4 flex items-center justify-between">
        <FaArrowLeft
          onClick={prevPage}
          className={`h-6   w-6 cursor-pointer ${
            currentPage === 1 ? "opacity-50" : "opacity-100"
          }`}
          style={{
            color: "#fff",
          }}
          disabled={currentPage === 1}
        />
        <span className="text-white">
          Page {currentPage} of {Math.ceil(data.length / itemsPerPage)}
        </span>
        <FaArrowRight
          onClick={nextPage}
          className={`h-6 w-6 cursor-pointer ${
            currentPage === Math.ceil(data.length / itemsPerPage)
              ? "opacity-50"
              : "opacity-100"
          }`}
          style={{
            color: "#fff",
          }}
          disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
        />
      </div>
    </div>
  );
}
