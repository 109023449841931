import React from "react";

function GettingStarted() {
  return (
    <div>
      <div data-tf-live="01JHJPFPEG5G9JV1XZ0PVPQ1H6"></div>
    </div>
  );
}

export default GettingStarted;
