import React, { useState } from "react";
import avatar from "assets/img/avatars/admin.jpg";
import banner from "assets/img/profile/banner.png";
import Card from "components/card";
import { Link } from "react-router-dom";
import InputField from "components/fields/InputField";
import Investment from "views/admin/userprofile/investment/index";
import axios from "axios";
import { FaClock } from "react-icons/fa";
import { useParams } from "react-router";
import Transaction from "views/admin/userprofile/transaction/index";
import ProfitBook from "views/admin/userprofile/profitbooking/index";
import General from "./General";
import Tables from "./Tables";
import InvestmentTable from "./InvestmentTable";
import SlotSheet from "./SlotSheet";
import { MdCancel } from "react-icons/md";
import { MdVerified } from "react-icons/md";
import KYCPreviewSlider from "views/user/UserProfile/Components/Sub-Components/KYCslider";
import PayoutModel from "../../payoutModal/index";
import { toast } from "react-toastify";
import PayoutTable from "views/user/payout/components/PayoutTable";
const Banner = (props) => {
  const { user, investamount, transaction, currentinvestment, payouts } = props;
  const [showModal, setShowModal] = React.useState(false);
  const [ModalData, setModalData] = React.useState("");
  const [withdrawal, setWithdrawal] = React.useState(0);
  const [file, setFile] = React.useState(null);
  const { id } = useParams();
  const [kycStatus, setKycStatus] = useState(user.kyc); // Local state for KYC status
  const [loading, setLoading] = useState(false); // Loading state for button
  const token = localStorage.getItem("token");

  const handleKYCUpdate = async () => {
    setLoading(true);
    try {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/user/updateuser/${id}`,
          {
            ...user,
            kyc: kycStatus,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log("response", res);
          // Show success message
          toast.success("KYC details updated successfully.");
          setKycStatus("waiting"); // Update local state for KYC status
        });
    } catch (err) {
      console.error("Error saving KYC details:", err);
      alert("Failed to save KYC details. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    setWithdrawal(user.withdrawal);
  }, [user]);

  const [uploadError, setUploadError] = React.useState("");
  const [uploading, setUploading] = React.useState(false);

  // Add this validation function
  const validateFile = (file) => {
    const allowedTypes = [
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    const maxSize = 10 * 1024 * 1024; // 10MB

    if (!allowedTypes.includes(file.type)) {
      return "Only PDF and Excel files are allowed";
    }
    if (file.size > maxSize) {
      return "File size must be less than 10MB";
    }
    return null;
  };

  // Replace your existing handleFileUpload with this enhanced version
  const handleFileUpload = async () => {
    if (!file) {
      setUploadError("Please select a file before uploading.");
      return;
    }

    const validationError = validateFile(file);
    if (validationError) {
      setUploadError(validationError);
      return;
    }

    setUploading(true);
    setUploadError("");
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/upload/${user._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            console.log(`Upload Progress: ${percentCompleted}%`);
          },
        }
      );

      alert("File uploaded successfully");
      console.log(response.data);
      setFile(null);
      setShowModal(false);
    } catch (err) {
      console.error(err);
      setUploadError(err.response?.data?.message || "Error uploading file");
    } finally {
      setUploading(false);
    }
  };

  console.log(user);
  const handlewithdrawal = () => {
    if (withdrawal < user.withdrawal) {
      alert("Withdrawal cannot be less than the previous withdrawal");
      return;
    }
    if (withdrawal > 7) {
      alert("Withdrawal cannot be more than 7");
      return;
    }
    user.withdrawal = withdrawal;
    axios
      .post(process.env.REACT_APP_BASE_URL + `/user/updateuser/${id}`, user, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log("Payout Response", res.data);
        setShowModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log(investamount);
  return (
    <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
      {/* Background and profile */}
      <div
        className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="absolute -bottom-12 flex h-[107px] w-[107px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
          <img
            className="h-full w-full rounded-full"
            src={user.photo || avatar}
            alt=""
          />
        </div>
      </div>

      {/* Name and position */}
      <div className="mt-16 flex flex-col items-center">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          {user.username}
        </h4>
        <p className="flex items-center justify-center gap-1 text-base font-normal text-gray-600">
          {user.email}{" "}
          {user.kyc === "true" ? (
            <span title="KYC Verified" className="text-green-600">
              {" "}
              <MdVerified />
            </span>
          ) : user.kyc === "false" ? (
            <span title="KYC not verified" className="text-red-500">
              {" "}
              <MdCancel />
            </span>
          ) : (
            <span title="KYC in review" className="text-yellow-500">
              {" "}
              <FaClock />
            </span>
          )}
        </p>
      </div>

      <div className="mt-4 flex h-full w-full items-center justify-center">
        <div className="flex h-1/2 w-1/2 flex-row !justify-between rounded-2xl px-6 py-3 shadow-2xl shadow-shadow-500  dark:shadow-none">
          <div className="flex flex-col items-center justify-center">
            <div className="flex items-center justify-center">
              <Link
                className="ml-1 text-sm font-normal text-gray-600"
                onClick={() => {
                  setModalData("Payout");
                  setShowModal(true);
                }}
              >
                Add Payout Count
              </Link>
            </div>
          </div>

          <div className="h-11 w-px bg-gray-300 dark:bg-white/10" />
          <div className="flex flex-col items-center justify-center">
            <div className="flex items-center justify-center">
              <Link
                className="ml-1 text-sm font-normal text-gray-600"
                onClick={() => {
                  setModalData("Transaction");
                  setShowModal(true);
                }}
              >
                {" "}
                Add Transaction
              </Link>
            </div>
            <p className="mt-px text-xl font-bold text-navy-700 dark:text-white"></p>
          </div>
          <div className="h-11 w-px bg-gray-300 dark:bg-white/10" />

          <div className="flex flex-col items-center justify-center">
            <div className="flex items-center justify-center">
              <Link
                onClick={() => {
                  setModalData("Investment");
                  setShowModal(true);
                }}
                className="ml-1 min-w-fit text-sm font-normal text-gray-600"
              >
                Add Investment
              </Link>
            </div>
          </div>
        </div>

        {showModal ? (
          <>
            <div
              className="fixed inset-0 z-50 flex w-full
              items-center justify-center overflow-y-auto 
            overflow-x-hidden rounded-lg bg-[#171717] bg-opacity-70 outline-none focus:outline-none"
            >
              <div
                className="relative  my-6 mx-auto max-w-4xl"
                style={{
                  width: "100%",
                  borderRadius: "20px",
                  border: "1px solid #404040",
                  // background: "#252525 ",
                  backdropFilter: "blur(25px)",
                }}
              >
                {/*content*/}
                <div className=" relative flex w-full flex-col p-10">
                  {/*header*/}
                  <div className="flex items-start justify-between rounded-t border-b border-solid pt-5 pb-5">
                    <h3 className="text-3xl font-semibold">
                      Add {ModalData && ModalData}
                    </h3>
                    <button
                      className="text-[#F9F9F9]-500 outline-nonemr-1 mb-1 flex flex-row gap-2 px-6 py-2 text-sm font-bold uppercase transition-all duration-150 ease-linear"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <line
                          x1="10"
                          y1="10"
                          x2="90"
                          y2="90"
                          stroke="#F9F9F9"
                          stroke-width="10"
                        />
                        <line
                          x1="90"
                          y1="10"
                          x2="10"
                          y2="90"
                          stroke="#F9F9F9"
                          stroke-width="10"
                        />
                      </svg>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative ">
                    {ModalData === "Investment" ? <Investment /> : null}
                    {ModalData === "Payout" ? (
                      <PayoutModel id={user._id} user1={user} />
                    ) : null}
                    {ModalData === "Transaction" ? <Transaction /> : null}
                    {ModalData === "Profitbook" ? <ProfitBook /> : null}
                  </div>
                  {/*footer*/}
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
      <div className="h-px w-full bg-gray-300 dark:bg-white/10" />

      <div className="h-px w-full bg-gray-300 dark:bg-white/10" />
      <General user={user} />

      <div className="h-px w-full bg-gray-300 dark:bg-white/10" />
      {
        <section className="flex w-full flex-col justify-start gap-4 p-4">
          {/* KYC Header */}
          <div className="text-3xl font-bold text-navy-700 dark:text-white">
            KYC
          </div>

          {/* KYC Status Update Menu */}
          <div className="flex items-center justify-between gap-4">
            <div className="flex items-center justify-between gap-4">
              <div className="flex items-center gap-2">
                <label className="text-lg font-medium text-navy-700 dark:text-white">
                  Update KYC Status:
                </label>
                <select
                  value={kycStatus}
                  onChange={(e) => setKycStatus(e.target.value)}
                  className="rounded-md border border-gray-300 bg-white p-1.5 text-navy-700 
                 dark:border-[#2f333a] dark:bg-[#1c1e21] dark:text-white"
                >
                  <option value="true">Verified</option>
                  <option value="waiting">Review</option>
                  <option value="false">Not Verified</option>
                </select>
              </div>
            </div>

            <button
              onClick={handleKYCUpdate}
              className={`bg-gd  w-40 rounded-md p-2 text-white ${
                loading ? "bg-gray-400" : "bg-blue-600 hover:bg-blue-700"
              }`}
              disabled={loading}
            >
              {loading ? "Updating..." : "Update KYC"}
            </button>
          </div>

          <KYCPreviewSlider
            kycStatus={user.kyc}
            aadharFront={user.aadharcardfront}
            aadharBack={user.aadharcardback}
            panCard={user.pancard}
          />
        </section>
      }
      <div className="h-px w-full bg-gray-300 dark:bg-white/10" />
      <Tables
        columnsData={[
          {
            Header: "NAME",
            accessor: "name",
          },
          {
            Header: "AMOUNT",
            accessor: "amount",
          },
          {
            Header: "TYPE",
            accessor: "type",
          },
          {
            Header: "PAYMENT METHOD",
            accessor: "PaymentMethod",
          },
          {
            Header: "DATE (MM/DD/YYYY)",
            accessor: "date",
          },
          {
            Header: "DELETE",
            accessor: "delete",
          },
        ]}
        tableData={transaction}
      />
      <div className="h-px w-full bg-gray-300 dark:bg-white/10" />
      {payouts && (
        <section className="mb-2 w-full p-4">
          <div className="relative mt-5 mb-5 flex items-center justify-between">
            <div className="text-3xl font-bold text-navy-700 dark:text-white">
              Payouts
            </div>
          </div>
          <PayoutTable
            isAdmin={true}
            columnData={[
              {
                Header: "UTR/REFERENCE",
                accessor: "_id",
              },
              {
                Header: "Type",
                accessor: "type",
              },
              {
                Header: "Payment",
                accessor: "PaymentMethod",
              },
              {
                Header: "Amount",
                accessor: "amount",
              },

              {
                Header: "Date & Time",
                accessor: "date",
              },
            ]}
            cellData={payouts}
          />
        </section>
      )}
      <div className="h-px w-full bg-gray-300 dark:bg-white/10" />
      <InvestmentTable
        columnsData={[
          {
            Header: "COIN NAME",
            accessor: "coinname",
          },
          {
            Header: "INVESTMENT AMOUNT",
            accessor: "investamount",
          },
          {
            Header: "COIN PRICE",
            accessor: "coinprice",
          },
          {
            Header: "COIN QUANTITY",
            accessor: "coinquantity",
          },
          {
            Header: "DATE (MM/DD/YYYY)",
            accessor: "investdate",
          },
          {
            Header: "TRADE",
            accessor: "_id",
          },
        ]}
        tableData={currentinvestment}
      />
      {/* {showModal && ModalData === "UploadSheet" && (
        <div className="fixed inset-0 z-50 flex w-full items-center justify-center bg-[#171717] bg-opacity-70">
          <div className="bg-black relative rounded-lg p-6 shadow-lg">
            <h3 className="mb-4 text-xl font-bold">Upload Document</h3>

            <div className="mb-4">
              <div className="rounded-lg border-2 border-dashed border-gray-300 p-4">
                <input
                  type="file"
                  accept=".pdf, .xlsx, .xls"
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                    setUploadError("");
                  }}
                  className="w-full"
                />
                <p className="mt-2 text-sm text-gray-500">
                  Supported formats: PDF, Excel (max 10MB)
                </p>
              </div>

              {uploadError && (
                <p className="mt-2 text-sm text-red-500">{uploadError}</p>
              )}

              {file && (
                <div className="mt-2">
                  <p className="text-sm text-gray-600">
                    Selected file: {file.name}
                  </p>
                </div>
              )}
            </div>

            <div className="flex justify-end gap-4">
              <button
                onClick={() => setShowModal(false)}
                className="rounded bg-gray-400 px-4 py-2 text-white transition-colors hover:bg-gray-500"
              >
                Cancel
              </button>
              <button
                onClick={handleFileUpload}
                disabled={uploading || !file}
                className={`rounded px-4 py-2 text-white transition-colors ${
                  uploading || !file
                    ? "cursor-not-allowed bg-blue-400"
                    : "bg-blue-600 hover:bg-blue-700"
                }`}
              >
                {uploading ? "Uploading..." : "Upload"}
              </button>
            </div>
          </div>
        </div>
      )} */}
      {/* <section className="mt-4">
        <button
          onClick={() => {
            // setModalData("UploadSheet");
            setShowModal(true);
          }}
          className="rounded bg-blue-600 px-4 py-2 text-white transition-colors hover:bg-blue-700"
        >
          Upload File
        </button>
      </section> */}
      {/* <hr className="border-1 border-t border-white" /> */}
      <div className="h-px w-full bg-gray-300 dark:bg-white/10" />
      <div className="mt-4">
        <SlotSheet userId={user._id} isAdmin={true} />
      </div>
    </Card>
  );
};

export default Banner;
