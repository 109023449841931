import axios from "axios";
import React, { useEffect, useState } from "react";
import { MdPendingActions } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import TransactionContainer from "../transactions/Sections/TransactionContainer";
import glowPink from "../../../assets/img/landings/glowpink.png";
import glowCyan from "../../../assets/img/landings/glowCyan.png";
import PayoutContainer from "./sections/PayoutContainer";
function Payout() {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  useEffect(() => {
    const id = localStorage.getItem("id");
    axios
      .get(process.env.REACT_APP_BASE_URL + `/user/getuser`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const user = res.data.find((item) => item._id === id);
        setUser(user);
        if (!user.client) {
          navigate("/user/profile");
        }
      });
  }, []);
  return (
    <>
      {user && user.kyc === "false" && user.client === true ? (
        // Add what you want to show when KYC is waiting and user is a client
        <div className="flex min-h-[60vh] w-full flex-col items-center justify-center text-white">
          <span className="text-6xl">
            <MdPendingActions />
          </span>
          <h2 className="text-3xl font-bold">KYC Pending</h2>
          <p>Please complete your KYC to access this feature</p>
        </div>
      ) : (
        <div className="relative  text-white lg:p-4">
          <div
            className="!
          z-50 "
          >
            <PayoutContainer />
          </div>
          {/* <img
          
            src={glowPink}
            draggable={false}
            className="absolute left-[1rem] top-[1rem] !z-0 h-[30rem] w-[34rem] opacity-70 blur-2xl"
            alt=""
          /> */}
          <img
            src={glowCyan}
            draggable={false}
            className="absolute right-4 bottom-8 !z-0 h-64 w-64 opacity-70 blur-2xl"
            alt=""
          />
        </div>
      )}
    </>
  );
}

export default Payout;
