import React, { useState } from "react";
import TransactionContainer from "./Sections/TransactionContainer";
import glowPink from "../../../assets/img/landings/glowpink.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MdPendingActions } from "react-icons/md";
export default function Transaction() {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  React.useEffect(() => {
    const id = localStorage.getItem("id");
    axios
      .get(process.env.REACT_APP_BASE_URL + `/user/getuser`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const user = res.data.find((item) => item._id === id);
        setUser(user);
        if (!user.client) {
          navigate("/user/profile");
        }
      });
  }, []);
  return (
    <>
      {user && user.kyc === "false" && user.client === true ? (
        // Add what you want to show when KYC is waiting and user is a client
        <div className="flex min-h-[60vh] w-full flex-col items-center justify-center text-white">
          <span className="text-6xl">
            <MdPendingActions />
          </span>
          <h2 className="text-3xl font-bold">KYC Pending</h2>
          <p>Please complete your KYC to access this feature</p>
        </div>
      ) : (
        <div className="relative  text-white lg:p-4">
          <div className="z-30">
            <TransactionContainer />
          </div>
          <img
            src={glowPink}
            draggable="false"
            className="absolute top-[-16rem] left-[-16rem] z-[-1] h-[42rem] opacity-60 "
            alt=""
          />
        </div>
      )}
    </>
  );
}
