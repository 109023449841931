import axios from "axios";
import Card from "components/card";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { formatDate } from "utils/utility";
import { FaEdit } from "react-icons/fa";
import InputField from "components/fields/InputField";
import { formatToINR } from "utils/utility";
const General = (props) => {
  const { user } = props;
  const { id } = useParams();
  const [withdrawal, setWithdrawal] = React.useState(0);
  const [showModal, setShowModal] = React.useState(false);
  console.log(user);
  const [isEditing, setIsEditing] = useState(false); // Track edit mode
  const [editedDate, setEditedDate] = useState(user.JoinDate);

  const handleEditClick = () => {
    setIsEditing(true); // Enter edit mode
  };

  const handleSaveClick = async () => {
    // try {
    toast.success("Updated");
    //   await updateJoinDate(newDate); // Call the backend update function
    //   setIsEditing(false); // Exit edit mode
    // } catch (error) {
    //   console.error("Failed to update join date:", error);
    // }
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/user/updateDate/${id}`,
        { JoinDate: editedDate },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // or use any other method to get the token
          },
        }
      )
      .then((response) => {
        if (response.status == 200)
          toast.success("Join date updated successfully");
      })
      .catch((error) => {
        console.error("Error updating JoinDate:", error);
        alert(error.response?.data?.message || "Failed to update JoinDate.");
      });
  };

  const handleDateChange = (e) => {
    setEditedDate(e.target.value);
  };
  return (
    <div className={"h-full w-full p-3"}>
      {/* Header */}

      {/* Cards */}
      <div className="grid grid-cols-3 gap-4 px-2">
        <div className="flex items-center gap-3   px-3 py-4 ">
          <div
            style={{
              backgroundColor: "#222222",
              color: "#fff",
              padding: "10px",
              borderRadius: "50%",
            }}
          >
            <svg
              width="19"
              height="18"
              viewBox="0 0 19 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1155_4977)">
                <path
                  d="M18.4655 4.15649L12.152 10.47C11.448 11.1722 10.4943 11.5665 9.5 11.5665C8.50569 11.5665 7.55197 11.1722 6.848 10.47L0.5345 4.15649C0.524 4.27499 0.5 4.38224 0.5 4.49999V13.5C0.501191 14.4942 0.896661 15.4473 1.59966 16.1503C2.30267 16.8533 3.2558 17.2488 4.25 17.25H14.75C15.7442 17.2488 16.6973 16.8533 17.4003 16.1503C18.1033 15.4473 18.4988 14.4942 18.5 13.5V4.49999C18.5 4.38224 18.476 4.27499 18.4655 4.15649Z"
                  fill="#717171"
                />
                <path
                  d="M11.0921 9.4095L17.9426 2.55825C17.6107 2.00799 17.1427 1.55253 16.5836 1.2358C16.0245 0.919067 15.3932 0.751755 14.7506 0.75H4.25059C3.60801 0.751755 2.97672 0.919067 2.41761 1.2358C1.85851 1.55253 1.39045 2.00799 1.05859 2.55825L7.9091 9.4095C8.33177 9.83048 8.90403 10.0669 9.5006 10.0669C10.0972 10.0669 10.6694 9.83048 11.0921 9.4095Z"
                  fill="#717171"
                />
              </g>
              <defs>
                <clipPath id="clip0_1155_4977">
                  <rect
                    width="18"
                    height="18"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>

          <div>
            <p className="text-[12px] text-[#717171]">Name</p>
            <p className=" text-sm font-medium text-navy-700 dark:text-white">
              {user.email}
            </p>
          </div>
        </div>

        <div className="flex items-center gap-3   px-3 py-4 ">
          <div
            style={{
              backgroundColor: "#222222",
              color: "#fff",
              padding: "10px",
              borderRadius: "50%",
            }}
          >
            <svg
              width="19"
              height="18"
              viewBox="0 0 19 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1155_4983)">
                <path
                  d="M14 9C13.2098 9.00092 12.4333 8.7935 11.7488 8.39865C11.0643 8.0038 10.496 7.43547 10.1012 6.75096C9.70642 6.06645 9.49904 5.28995 9.50001 4.49974C9.50098 3.70953 9.71026 2.93354 10.1068 2.25H3.6815L3.65 1.986C3.58537 1.43895 3.32229 0.934615 2.91064 0.568578C2.49899 0.202541 1.96736 0.000234627 1.4165 0L1.25 0C1.05109 0 0.860322 0.0790176 0.71967 0.21967C0.579018 0.360322 0.5 0.551088 0.5 0.75C0.5 0.948912 0.579018 1.13968 0.71967 1.28033C0.860322 1.42098 1.05109 1.5 1.25 1.5H1.4165C1.6002 1.50002 1.7775 1.56747 1.91478 1.68954C2.05206 1.81161 2.13976 1.97981 2.16125 2.16225L3.19325 10.9373C3.30039 11.8498 3.73886 12.6913 4.42543 13.302C5.112 13.9127 5.99889 14.25 6.91775 14.25H14.75C14.9489 14.25 15.1397 14.171 15.2803 14.0303C15.421 13.8897 15.5 13.6989 15.5 13.5C15.5 13.3011 15.421 13.1103 15.2803 12.9697C15.1397 12.829 14.9489 12.75 14.75 12.75H6.91775C6.45341 12.7488 6.0008 12.6041 5.62201 12.3355C5.24321 12.067 4.95678 11.6878 4.802 11.25H13.7428C14.622 11.2501 15.4733 10.9412 16.1479 10.3773C16.8225 9.81348 17.2775 9.03052 17.4335 8.16525L17.612 7.176C17.1948 7.74162 16.6506 8.20136 16.0232 8.51817C15.3959 8.83499 14.7028 9.00003 14 9Z"
                  fill="#717171"
                />
                <path
                  d="M5.75 18.0005C6.57842 18.0005 7.24999 17.3289 7.24999 16.5005C7.24999 15.6721 6.57842 15.0005 5.75 15.0005C4.92157 15.0005 4.25 15.6721 4.25 16.5005C4.25 17.3289 4.92157 18.0005 5.75 18.0005Z"
                  fill="#717171"
                />
                <path
                  d="M13.25 18.0005C14.0784 18.0005 14.75 17.3289 14.75 16.5005C14.75 15.6721 14.0784 15.0005 13.25 15.0005C12.4216 15.0005 11.75 15.6721 11.75 16.5005C11.75 17.3289 12.4216 18.0005 13.25 18.0005Z"
                  fill="#717171"
                />
                <path
                  d="M12.2991 6.70931C12.4281 6.84863 12.584 6.96033 12.7574 7.03768C12.9307 7.11503 13.118 7.1564 13.3078 7.15931H13.3326C13.5186 7.15992 13.7029 7.12358 13.8748 7.05238C14.0467 6.98118 14.2027 6.87655 14.3338 6.74456L17.5378 3.54056C17.6792 3.39953 17.7587 3.20814 17.7589 3.00848C17.7591 2.80883 17.68 2.61726 17.539 2.47594C17.3979 2.33461 17.2065 2.25509 17.0069 2.25488C16.8072 2.25467 16.6157 2.33378 16.4743 2.47481L13.3341 5.62031L12.4086 4.62731C12.2727 4.48181 12.0846 4.39623 11.8857 4.38941C11.6867 4.38259 11.4932 4.45508 11.3477 4.59094C11.2022 4.72679 11.1166 4.91489 11.1098 5.11384C11.103 5.31279 11.1755 5.50631 11.3113 5.65181L12.2991 6.70931Z"
                  fill="#717171"
                />
              </g>
              <defs>
                <clipPath id="clip0_1155_4983">
                  <rect
                    width="18"
                    height="18"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>

          <div>
            <p className="text-[12px] text-[#717171]">Balance</p>
            <p className=" text-sm font-medium text-navy-700 dark:text-white">
              ₹{user.balance ? formatToINR(user.balance) : "Not Available"}
            </p>
          </div>
        </div>

        <div className="flex items-center gap-3   px-3 py-4 ">
          <div
            style={{
              backgroundColor: "#222222",
              color: "#fff",
              padding: "10px",
              borderRadius: "50%",
            }}
          >
            <svg
              width="19"
              height="18"
              viewBox="0 0 19 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1155_4989)">
                <path
                  d="M14 9C13.2098 9.00092 12.4333 8.7935 11.7488 8.39865C11.0643 8.0038 10.496 7.43547 10.1012 6.75096C9.70642 6.06645 9.49904 5.28995 9.50001 4.49974C9.50098 3.70953 9.71026 2.93354 10.1068 2.25H3.6815L3.65 1.986C3.58537 1.43895 3.32229 0.934615 2.91064 0.568578C2.49899 0.202541 1.96736 0.000234627 1.4165 0L1.25 0C1.05109 0 0.860322 0.0790176 0.71967 0.21967C0.579018 0.360322 0.5 0.551088 0.5 0.75C0.5 0.948912 0.579018 1.13968 0.71967 1.28033C0.860322 1.42098 1.05109 1.5 1.25 1.5H1.4165C1.6002 1.50002 1.7775 1.56747 1.91478 1.68954C2.05206 1.81161 2.13976 1.97981 2.16125 2.16225L3.19325 10.9373C3.30039 11.8498 3.73886 12.6913 4.42543 13.302C5.112 13.9127 5.99889 14.25 6.91775 14.25H14.75C14.9489 14.25 15.1397 14.171 15.2803 14.0303C15.421 13.8897 15.5 13.6989 15.5 13.5C15.5 13.3011 15.421 13.1103 15.2803 12.9697C15.1397 12.829 14.9489 12.75 14.75 12.75H6.91775C6.45341 12.7488 6.0008 12.6041 5.62201 12.3355C5.24321 12.067 4.95678 11.6878 4.802 11.25H13.7428C14.622 11.2501 15.4733 10.9412 16.1479 10.3773C16.8225 9.81348 17.2775 9.03052 17.4335 8.16525L17.612 7.176C17.1948 7.74162 16.6506 8.20136 16.0232 8.51817C15.3959 8.83499 14.7028 9.00003 14 9Z"
                  fill="#717171"
                />
                <path
                  d="M5.75 18.0005C6.57842 18.0005 7.24999 17.3289 7.24999 16.5005C7.24999 15.6721 6.57842 15.0005 5.75 15.0005C4.92157 15.0005 4.25 15.6721 4.25 16.5005C4.25 17.3289 4.92157 18.0005 5.75 18.0005Z"
                  fill="#717171"
                />
                <path
                  d="M13.25 18.0005C14.0784 18.0005 14.75 17.3289 14.75 16.5005C14.75 15.6721 14.0784 15.0005 13.25 15.0005C12.4216 15.0005 11.75 15.6721 11.75 16.5005C11.75 17.3289 12.4216 18.0005 13.25 18.0005Z"
                  fill="#717171"
                />
                <path
                  d="M12.2991 6.70931C12.4281 6.84863 12.584 6.96033 12.7574 7.03768C12.9307 7.11503 13.118 7.1564 13.3078 7.15931H13.3326C13.5186 7.15992 13.7029 7.12358 13.8748 7.05238C14.0467 6.98118 14.2027 6.87655 14.3338 6.74456L17.5378 3.54056C17.6792 3.39953 17.7587 3.20814 17.7589 3.00848C17.7591 2.80883 17.68 2.61726 17.539 2.47594C17.3979 2.33461 17.2065 2.25509 17.0069 2.25488C16.8072 2.25467 16.6157 2.33378 16.4743 2.47481L13.3341 5.62031L12.4086 4.62731C12.2727 4.48181 12.0846 4.39623 11.8857 4.38941C11.6867 4.38259 11.4932 4.45508 11.3477 4.59094C11.2022 4.72679 11.1166 4.91489 11.1098 5.11384C11.103 5.31279 11.1755 5.50631 11.3113 5.65181L12.2991 6.70931Z"
                  fill="#717171"
                />
              </g>
              <defs>
                <clipPath id="clip0_1155_4989">
                  <rect
                    width="18"
                    height="18"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>

          <div>
            <p className="text-[12px] text-[#717171]">Last Transaction</p>
            <p className=" text-sm font-medium text-navy-700 dark:text-white">
              {user.address ? user.address : "Not Available"}
            </p>
          </div>
        </div>

        <div className="flex items-center gap-3   px-3 py-4 ">
          <div
            style={{
              backgroundColor: "#222222",
              color: "#fff",
              padding: "10px",
              borderRadius: "50%",
            }}
          >
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1155_4995)">
                <path
                  d="M11.75 0.5H7.25C6.2558 0.501191 5.30267 0.896661 4.59966 1.59966C3.89666 2.30267 3.50119 3.2558 3.5 4.25V12.5H15.5V4.25C15.4988 3.2558 15.1033 2.30267 14.4003 1.59966C13.6973 0.896661 12.7442 0.501191 11.75 0.5V0.5Z"
                  fill="#717171"
                />
                <path
                  d="M3.5 14.75C3.50119 15.7442 3.89666 16.6973 4.59966 17.4003C5.30267 18.1033 6.2558 18.4988 7.25 18.5H11.75C12.7442 18.4988 13.6973 18.1033 14.4003 17.4003C15.1033 16.6973 15.4988 15.7442 15.5 14.75V14H3.5V14.75ZM9.5 15.5C9.64834 15.5 9.79334 15.544 9.91668 15.6264C10.04 15.7088 10.1361 15.8259 10.1929 15.963C10.2497 16.1 10.2645 16.2508 10.2356 16.3963C10.2067 16.5418 10.1352 16.6754 10.0303 16.7803C9.92544 16.8852 9.7918 16.9567 9.64632 16.9856C9.50083 17.0145 9.35003 16.9997 9.21299 16.9429C9.07594 16.8861 8.95881 16.79 8.8764 16.6667C8.79399 16.5433 8.75 16.3983 8.75 16.25C8.75 16.0511 8.82902 15.8603 8.96967 15.7197C9.11032 15.579 9.30109 15.5 9.5 15.5Z"
                  fill="#717171"
                />
              </g>
              <defs>
                <clipPath id="clip0_1155_4995">
                  <rect
                    width="18"
                    height="18"
                    fill="white"
                    transform="translate(0.5 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>

          <div>
            <p className="text-[12px] text-[#717171]">Phone</p>
            <p className=" text-sm font-medium text-navy-700 dark:text-white">
              {user.phone ? user.phone : "Not Available"}
            </p>
          </div>
        </div>

        <div className="flex items-center gap-3   px-3 py-4 ">
          <div
            style={{
              backgroundColor: "#222222",
              color: "#fff",
              padding: "10px",
              borderRadius: "50%",
            }}
          >
            <svg
              width="19"
              height="18"
              viewBox="0 0 19 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1155_5001)">
                <path
                  d="M9.49942 0.0317383C7.51488 0.0339216 5.61223 0.823195 4.20887 2.22641C2.80552 3.62963 2.01605 5.53219 2.01367 7.51674C2.01367 9.44424 3.50617 12.4607 6.44992 16.4822C6.80037 16.9623 7.25921 17.3529 7.78912 17.6222C8.31903 17.8914 8.90503 18.0318 9.49942 18.0318C10.0938 18.0318 10.6798 17.8914 11.2097 17.6222C11.7396 17.3529 12.1985 16.9623 12.5489 16.4822C15.4927 12.4607 16.9852 9.44424 16.9852 7.51674C16.9828 5.53219 16.1933 3.62963 14.79 2.22641C13.3866 0.823195 11.484 0.0339216 9.49942 0.0317383ZM9.49942 10.5002C8.90608 10.5002 8.32606 10.3243 7.83271 9.99465C7.33936 9.665 6.95485 9.19647 6.72778 8.64829C6.50072 8.10011 6.44131 7.49691 6.55707 6.91497C6.67282 6.33302 6.95854 5.79848 7.3781 5.37892C7.79766 4.95936 8.33221 4.67364 8.91415 4.55788C9.49609 4.44213 10.0993 4.50154 10.6475 4.7286C11.1957 4.95566 11.6642 5.34018 11.9938 5.83353C12.3235 6.32688 12.4994 6.90689 12.4994 7.50024C12.4994 8.29589 12.1834 9.05895 11.6207 9.62156C11.0581 10.1842 10.2951 10.5002 9.49942 10.5002Z"
                  fill="#717171"
                />
              </g>
              <defs>
                <clipPath id="clip0_1155_5001">
                  <rect
                    width="18"
                    height="18"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>

          <div>
            {/* <p className="text-sm text-gray-600">Date of Birth</p>
            <p className="text-base font-medium text-navy-700 dark:text-white">
              {new Date(user.dob).toLocaleDateString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })} */}
            <p className="text-[12px] text-[#717171]">Address</p>
            <p className=" text-sm font-medium text-navy-700 dark:text-white">
              {user.address ? user.address : "Not Available"}
            </p>
          </div>
        </div>

        <div className="flex items-center gap-3   px-3 py-4 ">
          <div
            style={{
              backgroundColor: "#222222",
              color: "#fff",
              padding: "10px",
              borderRadius: "50%",
            }}
          >
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1155_5007)">
                <path
                  d="M14.714 17.7507H14.6892C14.306 17.7447 13.9415 17.5819 13.6812 17.3007L12.2427 15.8899C11.9547 15.5922 11.9615 15.1174 12.2592 14.8294C12.26 14.8287 12.2607 14.8279 12.2615 14.8272C12.5592 14.5414 13.0317 14.5497 13.319 14.8452L14.7147 16.2124L17.2205 13.7067C17.513 13.4142 17.9885 13.4142 18.281 13.7067C18.5735 13.9992 18.5735 14.4747 18.281 14.7672L15.7152 17.3329C15.4512 17.6007 15.0905 17.7514 14.714 17.7507Z"
                  fill="#717171"
                />
                <path
                  d="M11.0266 15.5114C11.0236 13.0386 13.0254 11.0316 15.4974 11.0279C16.4596 11.0264 17.3971 11.3354 18.1696 11.9091C19.4994 7.1204 16.6951 2.16065 11.9071 0.830897C7.11911 -0.498853 2.16011 2.30465 0.830356 7.0934C-0.499394 11.8821 2.30486 16.8426 7.09286 18.1724C8.66786 18.6096 10.3321 18.6096 11.9071 18.1724C11.3356 17.4029 11.0266 16.4699 11.0266 15.5114ZM10.2504 9.51665C10.2504 9.71615 10.1709 9.90665 10.0299 10.0476L7.77611 12.3021C7.47761 12.5901 7.00211 12.5819 6.71411 12.2834C6.43286 11.9924 6.43286 11.5304 6.71411 11.2394L8.74811 9.2054V5.7599C8.74811 5.34515 9.08411 5.0084 9.49961 5.0084C9.91436 5.0084 10.2504 5.34515 10.2504 5.7599V9.51665Z"
                  fill="#717171"
                />
              </g>
              <defs>
                <clipPath id="clip0_1155_5007">
                  <rect
                    width="18"
                    height="18"
                    fill="white"
                    transform="translate(0.5 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>

          <div>
            <p className="text-[12px] text-[#717171]">Date Joined</p>
            <div className="flex items-center gap-2">
              {isEditing ? (
                <input
                  type="datetime-local"
                  value={editedDate?.slice(0, 16)}
                  onChange={handleDateChange}
                  className="text-black rounded border bg-shadow-500 text-sm"
                />
              ) : (
                <p className="text-sm font-medium text-navy-700 dark:text-white">
                  {user.JoinDate ? formatDate(user.JoinDate) : "Not Available"}
                </p>
              )}
              {isEditing ? (
                <button
                  onClick={handleSaveClick}
                  className="ml-2 rounded px-3 py-1 text-sm text-white"
                >
                  Save
                </button>
              ) : (
                <button
                  onClick={handleEditClick}
                  className="ml-2 rounded  px-3  text-sm"
                >
                  <FaEdit />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default General;
