import React from "react";
import { TbTriangleFilled, TbTriangleInvertedFilled } from "react-icons/tb";
import { GoTriangleDown } from "react-icons/go";
import { PiArrowCircleUpLeftLight } from "react-icons/pi";
import { PiArrowCircleDownRightLight } from "react-icons/pi";
import Swal from "sweetalert2";
import deposit from "../../transactions/Assests/Deposit.svg";
import payout from "../../transactions/Assests/Payout.svg";
import { BsInfoCircle } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import { PiCircleFill } from "react-icons/pi";
import { BsArrowLeftCircle } from "react-icons/bs";
import { BsArrowRightCircle } from "react-icons/bs";
import { formatToINR } from "utils/utility";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import axios from "axios";
import PdfPreview from "utils/pdfViewer";
export default function PayoutTable({ columnData, cellData, isAdmin = false }) {
  console.log("Hello", cellData);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const tableStyle = {
    width: "100%",
  };

  const cellStyle = {
    padding: "8px",
  };

  const deletePayout = async (item) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this payout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
      backdrop: true, // Ensures overlay is dark
      color: "#fff", // Makes text white
      background: "#121212", // Dark background manually
      customClass: {
        popup: "swal2-dark",
      },
    });
    if (result.isConfirmed) {
      const deletePromise = axios.delete(
        `${process.env.REACT_APP_BASE_URL}/payout/${item._id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.promise(deletePromise, {
        pending: "Deleting Payout...",
        success: (res) => {
          console.log(res);
          return "Payout deleted successfully! 🎉";
        },
        error: "Failed to payout transaction. Please try again. 🚨",
      });
    }
  };
  if (columnData) {
    return (
      <>
        <table style={tableStyle} className="!rounded-xl text-[14px]">
          <thead className="rounded-xl text-center">
            <tr>
              {columnData.map((data, index) => (
                <th
                  className="truncate px-4 font-extralight text-cyan-300 lg:!px-10"
                  key={index}
                  style={cellStyle}
                >
                  {data.Header}
                </th>
              ))}
              {isAdmin && (
                <th
                  className="truncate px-4 font-extralight text-cyan-300 lg:!px-10"
                  style={cellStyle}
                >
                  Delete Payout
                </th>
              )}
            </tr>
          </thead>

          <tbody className="w-full rounded-b-xl pb-5 text-center">
            {!cellData ? (
              <tr className="!my-6 w-full">
                <td className="!w-full text-center" colSpan={columnData.length}>
                  <h1>No Payouts</h1>
                </td>
              </tr>
            ) : (
              cellData.map((item, index) => (
                <tr
                  key={index}
                  className="bordder-[2px] !my-6 border-b border-[#3e3e3e95]"
                >
                  <td className="flx flex-col items-center justify-center text-center">
                    {item.reference ? (
                      <>
                        <a
                          target="_blank"
                          className="text-brandLinear underline"
                          href={item.reference}
                        >
                          Click here
                        </a>
                        {/* <PdfPreview pdfUrl={item.reference} /> */}
                      </>
                    ) : (
                      <span className="ml-8">Not Available</span>
                    )}
                  </td>

                  <td style={cellStyle} className="p-auto text-center">
                    {item.type === "RECEIVE" ? (
                      <img
                        src={deposit}
                        alt="Deposit"
                        className="mx-auto h-6 w-6"
                      />
                    ) : (
                      <img
                        src={payout}
                        alt="Payout"
                        className="mx-auto h-6 w-6"
                      />
                    )}
                  </td>
                  <td style={cellStyle} className="text-center text-center">
                    <span>{item.payment}</span>
                  </td>
                  <td
                    style={cellStyle}
                    className={`text-center text-center ${
                      item.type === "RECEIVE"
                        ? "text-green-500"
                        : "text-red-500"
                    }`}
                  >
                    {item.type === "SEND" ? "-" : "+"} ₹
                    {formatToINR(item.amount)}
                  </td>

                  <td style={cellStyle} className=" text-center">
                    <div className="mx-auto w-full">
                      <span className=" text-center  text-[#bababa]">
                        {item.payoutDate ? (
                          new Date(item.payoutDate).toLocaleString("en-IN", {
                            year: "numeric",
                            month: "long",
                            day: "2-digit",
                          })
                        ) : (
                          <span className="text-[#8C8C8C]">Not Available</span>
                        )}
                      </span>
                    </div>
                  </td>
                  {isAdmin && (
                    <td
                      style={cellStyle}
                      className="flex items-center justify-center text-center"
                    >
                      <div
                        onClick={() => deletePayout(item)}
                        className="  flex w-fit rounded bg-red-400 p-1.5 text-[24px] text-[#000000]"
                      >
                        <MdDelete />
                      </div>
                    </td>
                  )}
                </tr>
              ))
            )}
          </tbody>
        </table>
        {!isAdmin && (
          <div className="mt-4 flex w-full items-center justify-center gap-4 px-16">
            <BsArrowLeftCircle className="text-[30px] opacity-40" />
            <div className="flex items-center justify-center gap-4 text-[#404040]">
              <span className="bg-gd rounded-full px-3 py-1 text-white">1</span>
              <span className="rounded-full p-2">2</span>
              <span className="rounded-full p-2">3</span>
              <span className="rounded-full p-2">4</span>
              <span className="rounded-full p-2">5</span>
              <span className="rounded-full p-2">6</span>
              <span className="rounded-full p-2">7</span>
              <span className="rounded-full p-2">8</span>
              <span className="rounded-full p-2">9</span>
            </div>
            <BsArrowRightCircle className="text-[30px]" />
          </div>
        )}
      </>
    );
  }
}
