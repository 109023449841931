import React from "react";
import Profile from "./Sections/Profile";
import Slootsheetuser from "../../user/Slotsheet/index";
export default function UserProfile() {
  return (
    <div>
      <Profile />
      {/* <UserTableContainer /> */}
      {/* <Slootsheetuser /> */}
    </div>
  );
}

// 66787799af059ee3564a1eec
