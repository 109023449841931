import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@sweetalert2/theme-dark/dark.css";
import "sweetalert2/dist/sweetalert2.min.css";
import App from "./App";
const script = document.createElement("script");
script.src = "//embed.typeform.com/next/embed.js";
script.async = true;
document.body.appendChild(script);
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <App />
    <ToastContainer stacked theme="dark" />
  </BrowserRouter>
);
