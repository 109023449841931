import axios from "axios";
import * as pdfjsLib from "pdfjs-dist";
import { toast } from "react-toastify";

// Initialize PDF.js worker
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const convertPDFToImage = async (file) => {
  try {
    // Convert file to ArrayBuffer
    const arrayBuffer = await file.arrayBuffer();

    // Load the PDF document using ArrayBuffer
    const loadingTask = pdfjsLib.getDocument({ data: arrayBuffer });
    const pdf = await loadingTask.promise;

    // Get the first page
    const page = await pdf.getPage(1);
    const viewport = page.getViewport({ scale: 3.0 }); // Higher scale for better quality

    // Create canvas
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    // Render PDF page to canvas
    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    };

    await page.render(renderContext).promise;

    // Convert canvas to blob
    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          const imageFile = new File([blob], "converted-pdf.jpg", {
            type: "image/jpeg",
            lastModified: new Date().getTime(),
          });
          resolve(imageFile);
        },
        "image/jpeg",
        0.95 // High quality
      );
    });
  } catch (error) {
    console.error("Error converting PDF to image:", error);
    throw new Error("Failed to convert PDF to image");
  }
};

const isFileTypeValid = (file) => {
  const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
  const validPdfType = "application/pdf";
  return validImageTypes.includes(file.type) || file.type === validPdfType;
};

const uploadToCloudinary = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append(
    "upload_preset",
    process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
  );

  try {
    const res = await axios.post(
      `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
      formData
    );
    return res.data.secure_url;
  } catch (error) {
    console.error("Error uploading to Cloudinary:", error);
    throw new Error("Failed to upload to Cloudinary");
  }
};

export const handleFileUpload = async (file, setIsUploading) => {
  if (!file) {
    toast.error("No file selected");
    return null;
  }

  if (!isFileTypeValid(file)) {
    toast.error("Please upload only images (JPEG, PNG) or PDF files");
    return null;
  }

  setIsUploading(true);

  try {
    let fileToUpload;
    let fileType = file.type;

    // If file is PDF, convert to image first
    if (file.type === "application/pdf") {
      toast.info("Converting PDF to image...");
      try {
        fileToUpload = await convertPDFToImage(file);
        fileType = "image/jpeg";
        toast.success("PDF converted successfully");
      } catch (error) {
        toast.error("Failed to convert PDF to image");
        setIsUploading(false);
        return null;
      }
    } else {
      // If it's already an image, use it as is
      fileToUpload = file;
    }

    // Upload the file (either original image or converted PDF)
    toast.info("Uploading file...");
    const fileUrl = await uploadToCloudinary(fileToUpload);

    setIsUploading(false);
    toast.success("File uploaded successfully");

    return {
      url: fileUrl,
      type: fileType,
    };
  } catch (error) {
    console.error("Error in file upload process:", error);
    toast.error("Failed to process file. Please try again.");
    setIsUploading(false);
    return null;
  }
};
