import { useEffect } from "react";
import Heading from "../components/Heading";
import Img1 from "../../../assets/img/landings/feature1.png";
import Img2 from "../../../assets/img/landings/feature2.png";
import Img3 from "../../../assets/img/landings/feature3.png";
import Img4 from "../../../assets/img/landings/handshake.png";
import Img5 from "../../../assets/img/landings/feature5.png";
import glowCyan from "../Assets/glowCyan.png";
import glowOrange from "../../../assets/img/landings/gloworange.png";
import glowpink from "../../../assets/img/landings/glowpink.png";
import "../../../assets/css/GlowMoving.css";

export default function FeaturesSection({ gd }) {
  

  return (
    <section  className="relative z-30 mt-20 flex w-full flex-col items-center gap-6 p-6 md:p-20">
      <Heading
        heading="Discover the Power of"
        subHeading="Titan Technologies"
        className="z-30 text-center"
         data-aos="fade-up"
      />
      <div  className="z-30 grid w-full grid-cols-1 gap-6 text-center md:grid-cols-3">
        <div className="z-30 flex w-full flex-col items-center gap-6 rounded-2xl border-[.5px] border-[#F7F7F71A]/10 bg-[#1919194D]/30 p-6 md:min-h-[24rem] md:p-12">
          <img src={Img1} className="h-24" alt="Cryptocurrency" />
          <h4 className="font-redzone text-2xl">Cryptocurrency</h4>
          <span className="text-app_gray/70" style={{ color: "#ACACAC" }}>
            Investing in various digital currencies, focusing on both
            well-established coins and promising altcoins.
          </span>
        </div>
        <div className="z-30 flex w-full flex-col items-center gap-6 rounded-2xl border-[.5px] border-[#F7F7F71A]/10 bg-[#1919194D]/30 p-6 md:min-h-[24rem] md:p-12">
          <img src={Img2} className="h-24" alt="NFTs (Non-Fungible Tokens)" />
          <h4 className="font-redzone text-2xl">NFTs (Non-Fungible Tokens)</h4>
          <span className="text-app_gray/70" style={{ color: "#ACACAC" }}>
            Acquiring unique digital assets, ranging from digital art to
            utility-driven tokens.
          </span>
        </div>
        <div className="z-30 flex w-full flex-col items-center gap-6 rounded-2xl border-[.5px] border-[#F7F7F71A]/10 bg-[#1919194D]/30 p-6 md:min-h-[24rem] md:p-12">
          <img src={Img3} className="h-24" alt="Futures Trading" />
          <h4 className="font-redzone text-2xl">Futures Trading</h4>
          <span className="text-app_gray/70" style={{ color: "#ACACAC" }}>
            Engaging in contractual agreements to buy or sell assets at
            predetermined future dates and prices.
          </span>
        </div>
      </div>
      <div className="z-30 flex w-full flex-col items-center gap-6 md:flex-row md:justify-center">
        <div
          className="z-30 flex w-full flex-col items-center gap-6 rounded-2xl bg-gradient-to-br from-white/10 p-6 md:min-h-[24rem] md:w-1/3 md:p-12"
          style={{
            borderRadius: "20px",
            border: "1px solid rgba(113, 113, 113, 0.20)",
            background: "rgba(25, 25, 25, 0.30)",
            backdropFilter: "blur(10px)",
          }}
        >
          <img src={Img4} className="h-24" alt="Contract Trading" />
          <h4 className="font-redzone text-2xl">Contract Trading</h4>
          <span
            className="text-app_gray/70 text-center"
            style={{ color: "#ACACAC" }}
          >
            Participating in derivative markets with products like options and
            swaps to hedge or speculate on price movements.
          </span>
        </div>
        <div
          className="z-30 flex w-full flex-col items-center gap-6 rounded-2xl bg-gradient-to-br from-white/10 p-6 md:min-h-[24rem] md:w-1/3 md:p-12"
          style={{
            borderRadius: "20px",
            border: "1px solid rgba(113, 113, 113, 0.20)",
            background: "rgba(25, 25, 25, 0.30)",
            backdropFilter: "blur(10px)",
          }}
        >
          <img src={Img5} className="h-24" alt="Credit" />
          <h4 className="font-redzone text-2xl">Credit</h4>
          <span
            className="text-app_gray/70 text-center"
            style={{ color: "#ACACAC" }}
          >
            Offering credit solutions or investing in credit instruments to
            diversify and manage risk effectively.
          </span>
        </div>
      </div>
      {gd ? (
        <>
          <img
            draggable="false"
            src={glowCyan}
            className="nonselectable moveCyan absolute top-[-250px] !z-0 h-[400px] w-[800px] lg:h-[800px]"
          />
          <img
            draggable="false"
            src={glowpink}
            className="nonselectable movePurple absolute left-[-500px] top-[-100px] !z-0 h-[750px] w-[1350px] lg:top-[-200px] lg:h-[1500px]"
          />
        </>
      ) : null}
    </section>
  );
}
