import React, { useState } from "react";
import glowCyan from "../Assets/glowCyan.png";
import glowOrange from "../../../assets/img/landings/gloworange.png";
import glowpink from "../../../assets/img/landings/glowpink.png";
import Modalwcu from "../components/Modalwcu";

export default function WhyChooseUs() {
  const [openModal, setOpenModal] = useState(false);
  return (
    <section
      id="calculator"
      className=" relative flex w-full flex-col items-center gap-6 p-6 md:p-20"
    >
      {openModal && (
        <Modalwcu setOpenModal={setOpenModal} openModal={openModal} />
      )}
      <h4 className=" text-app text-center text-3xl font-bold md:text-4xl">
        <span
          style={{
            background:
              "linear-gradient(to left, #9b51e0, #c655cc, #e162ba, #f076ad, #f78da7)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontFamily: "DM Sans",
            fontWeight: "700",
          }}
        >
          Why
        </span>{" "}
        Choose US?
      </h4>

      <p className="text-center">
        "Discover your potential returns by investing with us compared to other
        asset classes."
      </p>

      <div
        className="flex w-full flex-col gap-4 rounded-2xl bg-gradient-to-br from-white/10 p-6 sm:w-3/4 sm:p-10 lg:w-2/3"
        style={{
          borderRadius: "20px",
          border: "1px solid rgba(113, 113, 113, 0.20)",
          background: "rgba(25, 25, 25, 0.30)",
          backdropFilter: "blur(10px)",
        }}
      >
        <div className="relative flex flex-col items-center justify-around md:flex-row">
          <div className="p-4 md:p-10">
            <h4 className=" text-app text-center text-2xl md:text-3xl">
              Profit with Titan Technologies
              <br />
              <span
                style={{
                  color: "#00D084",
                }}
              >
                Upto 240% Yearly ROI
              </span>
            </h4>
          </div>
          {/* <button
            className="mt-4 rounded-lg bg-gradient-to-r from-[#f78da7] to-[#9b51e0] px-6 py-2 font-semibold text-white hover:from-[#f78da7] hover:to-[#9b51e0] md:mt-0 md:px-9 md:py-3"
            onClick={() => {
              setOpenModal(true);
            }}
            // style={{
            //   background:
            //     "linear-gradient(to left, #9b51e0, #c655cc, #e162ba, #f076ad, #f78da7)",
            //   letterSpacing: "0.1em",
            // }}
          >
            Calculate Now
          </button> */}
        </div>
      </div>
      <div className="z-30 m-3 flex w-full flex-row items-center justify-center gap-6 md:flex-row">
        <div
          className="w-1/3 p-4  md:p-10"
          style={{
            //                     border-radius: 20px;
            // border: 1px solid var(--card-stroke, rgba(113, 113, 113, 0.20));
            // opacity: 0.8;
            // background: var(--Card-bg, rgba(25, 25, 25, 0.30));
            // backdrop-filter: blur(10px);

            borderRadius: "20px",
            border: "1px solid rgba(113, 113, 113, 0.20)",
            opacity: "0.8",
            background: "rgba(25, 25, 25, 0.30)",
            backdropFilter: "blur(10px)",
          }}
        >
          <h4 className=" text-app mb-4 text-center text-4xl ">🤔</h4>
          <p className="text-center text-lg">
            Profits on bank's Fixed Deposits
            <br />
            <span className="font-bold">~ 6.7% to 7%</span>{" "}
            <span
              className="font-bold"
              style={{
                color: "red",
              }}
            >
              yearly
            </span>
          </p>
        </div>
        <div
          className="z-30 w-1/3 p-4 md:p-10 "
          style={{
            //                     border-radius: 20px;
            // border: 1px solid var(--card-stroke, rgba(113, 113, 113, 0.20));
            // opacity: 0.8;
            // background: var(--Card-bg, rgba(25, 25, 25, 0.30));
            // backdrop-filter: blur(10px);

            borderRadius: "20px",
            border: "1px solid rgba(113, 113, 113, 0.20)",
            opacity: "0.8",
            background: "rgba(25, 25, 25, 0.30)",
            backdropFilter: "blur(10px)",
          }}
        >
          <h4 className=" text-app mb-4 text-center text-4xl ">🫤</h4>
          <p className="text-center text-lg ">
            Profit on mutual funds & personal <br />
            portfolio are :
            <span className="font-bold">
              {" "}
              Subject to{" "}
              <span
                style={{
                  color: "red",
                }}
              >
                market risk
              </span>
            </span>
          </p>{" "}
        </div>
      </div>
      <img
        src={glowpink}
        draggable="false"
        className="nonselectable absolute top-[-200px] !z-0 h-[2000px] w-[2800px] opacity-70"
      />
    </section>
  );
}
