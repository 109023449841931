import React, { useState } from "react";
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
export default function PdfPreview({ pdfUrl }) {
  const [numPages, setNumPages] = useState(null);

  return (
    <div className="h-40 w-32 overflow-hidden border p-2 shadow-lg">
      <Document
        file={pdfUrl}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      >
        <Page pageNumber={1} width={120} />
      </Document>
    </div>
  );
}
