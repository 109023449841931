import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useParams,
  Link,
} from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar/index.jsx";
import Footer from "components/footer/Footer";
import routes from "routes.js";
import InputField from "components/fields/InputField";
import Dropdown from "components/dropdown";
import axios from "axios";
import Banner1 from "views/admin/marketplace/components/Banner";
import { toast } from "react-toastify";
export default function Admin(props) {
  const { id, user1 } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState("Add payout");
  const [user, setUser] = React.useState(user1);
  const [success, setSuccess] = React.useState(false);
  const [failed, setFailed] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [payout, setpayout] = React.useState({
    uid: id,
    payment: "",
    refernence: "",
    amount: 0,
    date: new Date(),
  });

  //   React.useEffect(() => {
  //     axios
  //       .get(process.env.REACT_APP_BASE_URL + `/user/getuser/`, {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       })
  //       .then((response) => {
  //         const res = response.data.filter((user) => user._id === id);
  //         console.log(res);
  //         setUser(res[0]);
  //         setLoading(false);
  //       });
  //   }, []);

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };

  const handleAddPayout = () => {
    axios
      .post(process.env.REACT_APP_BASE_URL + `/payout/create`, payout, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        toast.success("Payout added successfully");
        setSuccess(true);

        setTimeout(() => {
          setSuccess(false);
          navigate(`/admin/userdetail/${id}`);
        }, 1000);
      })
      .catch((err) => {
        setTimeout(() => {
          setSuccess(false);
          navigate(`/admin/userdetail/${id}`);
        }, 1000);
        toast.error(err.response.data.message);
        console.log(err.response.data);
      });
  };

  return (
    <>
      {loading ? (
        <div class="flex h-screen h-full w-full items-center justify-center ">
          {" "}
          <dotlottie-player
            src="https://lottie.host/42efddd6-e327-4839-88ba-098a0a6ef3f8/sg3Bewaizz.json"
            background="transparent"
            speed="1"
            style={{
              height: "300px",
              width: "300px",
            }}
            loop
            autoplay
          ></dotlottie-player>
        </div>
      ) : (
        <>
          <div className=" h-full w-full">
            <div class="mt-10 px-7">
              <div class="mt-7 grid w-full grid-cols-1 gap-7 md:grid-cols-1 lg:grid-cols-2">
                <div>
                  <p class="text-white-800 text-base font-medium leading-none">
                    Name
                  </p>
                  <InputField
                    class="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
                    value={`${user.name}`}
                    disabled
                  />
                </div>
                <div>
                  <p class="text-white-800 text-base font-medium leading-none">
                    email
                  </p>
                  <InputField
                    class="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
                    value={`${user.email}`}
                    disabled
                  />
                </div>
                <div>
                  <p class="text-white-800 text-base font-medium leading-none">
                    Date
                  </p>
                  <InputField
                    class="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 text-white outline-none"
                    type="date"
                    value={payout.date}
                    onChange={(e) =>
                      setpayout({
                        ...payout,
                        date: e.target.value,
                      })
                    }
                    required
                  />
                </div>
                <div>
                  <p class="text-white-800 text-base font-medium leading-none">
                    Amount
                  </p>
                  <InputField
                    class="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
                    type="number"
                    required
                    min="1"
                    onChange={(e) =>
                      setpayout({
                        ...payout,
                        amount: e.target.value,
                      })
                    }
                    value={payout.amount}
                  />
                </div>
                <div>
                  <p class="text-white-800 text-base font-medium leading-none">
                    UTR/Reference
                  </p>
                  <input
                    id="countries"
                    type="text"
                    required
                    class="mt-7 flex h-12 w-full items-center justify-center rounded-xl  border bg-white/0 p-3 text-sm outline-none"
                    onChange={(e) =>
                      setpayout({
                        ...payout,
                        reference: e.target.value,
                      })
                    }
                  />
                </div>
                <div>
                  <p class="text-white-800 text-base font-medium leading-none">
                    Payment Method
                  </p>
                  <InputField
                    class="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
                    type="text"
                    required
                    min="1"
                    onChange={(e) =>
                      setpayout({
                        ...payout,
                        payment: e.target.value,
                      })
                    }
                    value={payout.payment}
                  />
                </div>
              </div>
              <div className="mt-7 flex justify-end">
                <button
                  className=" bg-gd mr-1 mb-1 rounded px-6 py-3 text-sm  font-bold text-white outline-none transition-all duration-150 ease-linear focus:outline-none"
                  type="button"
                  onClick={handleAddPayout}
                >
                  Add payout
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
