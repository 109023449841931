import axios from "axios";

const getTransactionsAndPayouts = async (
  userId,
  setTotalDeposits,
  setTotalPayouts,
  setTransactions
) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/transaction/gettransaction`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    const data = response.data.filter((item) => item.uid === userId);

    let totalDeposits = 0;
    let totalPayouts = 0;

    data.forEach((item) => {
      if (item.type === "SEND") {
        totalPayouts += item.amount;
      } else {
        totalDeposits += item.amount;
      }
    });

    setTotalDeposits(totalDeposits);
    // setTotalPayouts(totalPayouts);
    setTransactions(data);
  } catch (error) {
    console.error("Error fetching transactions:", error);
  }
};

export default getTransactionsAndPayouts;
