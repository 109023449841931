"use client";

import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { MessageCircle, Repeat, Heart, Share, BarChart } from "lucide-react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import glowCyan from "../Assets/glowCyan.png";
import glowOrange from "../../../assets/img/landings/gloworange.png";
import glowpink from "../../../assets/img/landings/glowpink.png";
import "../../../assets/css/GlowMoving.css";
import logo from "../Assets/Icon.svg"; // Titan logo import

const API_URL = `${process.env.REACT_APP_BASE_URL}/api/tweets`;

const TweetsSection = () => {
  const [tweets, setTweets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    className: "custom-slider",
    dotsClass: "slick-dots custom-dots",
    appendDots: (dots) => (
      <div style={{ bottom: "-40px" }}>
        <ul>{dots}</ul>
      </div>
    ),
    customPaging: (i) => <div className="custom-dot"></div>,
  };

  useEffect(() => {
    const fetchTweetsWithRetry = async (retryCount = 0) => {
      try {
        setLoading(true);
        const response = await fetch(API_URL, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          credentials: "include",
        });

        if (!response.ok) {
          if (response.status === 429 && retryCount < 3) {
            const data = await response.json();
            const retryAfter =
              data.retryAfter || Math.pow(2, retryCount) * 1000;
            console.log(`Rate limited. Retrying in ${retryAfter}ms`);

            setTimeout(() => {
              fetchTweetsWithRetry(retryCount + 1);
            }, retryAfter);
            return;
          }
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setTweets(data.data || []);
        setError(null);
      } catch (err) {
        console.error("Fetch error:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTweetsWithRetry();
  }, []);

  if (loading) return <div>Loading tweets...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="tweets-slider-container scale-[130%]">
      <Slider {...sliderSettings}>
        {tweets.map((tweet) => (
          <div key={tweet.id} className="tweet-slide">
            <a
              href="https://x.com/titantechmedia"
              target="_blank"
              rel="noopener noreferrer"
              className="tweet-link"
            >
              <div className="tweet-card bg-black rounded-xl border border-gray-800">
                <div className="p-4">
                  <div className="flex items-start space-x-4">
                    <img
                      src={logo}
                      alt="Titan Logo"
                      className="h-12 w-12 rounded-full"
                    />
                    <div>
                      <div className="flex items-center space-x-2">
                        <span className="font-bold text-white">
                          Titan Technologies
                        </span>
                        <span className="text-blue-500">✓</span>
                      </div>
                      <p className="text-gray-500">@titantechmedia</p>
                    </div>
                  </div>
                  <p className="mt-4 text-white">{tweet.text}</p>
                  <div className="mt-4 flex justify-between text-gray-400">
                    <MessageCircle className="h-5 w-5" />
                    <Repeat className="h-5 w-5" />
                    <Heart className="h-5 w-5" />
                    <Share className="h-5 w-5" />
                  </div>
                </div>
              </div>
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TweetsSection;
