import { useState } from "react";
import { FaFilePdf } from "react-icons/fa";

const KYCPreviewSlider = ({
  kycStatus,
  aadharFront,
  aadharBack,
  panCard,
  documentTypes = {}, // Add document types prop
}) => {
  const [selectedItem, setSelectedItem] = useState(null); // Renamed from selectedImage

  // Helper function to determine if a URL is for a PDF
  const isPDF = (documentType) => documentType === "application/pdf";

  // Helper function to render preview item (either image or PDF icon)
  const renderPreviewItem = (url, documentType, title) => {
    if (isPDF(documentType)) {
      return (
        <div className="flex h-48 w-48 flex-col items-center justify-center rounded-md bg-[#262424] p-4 transition-all duration-150 hover:opacity-60">
          <FaFilePdf className="text-4xl text-red-500" />
          <span className="mt-2 text-center text-sm">{title}</span>
        </div>
      );
    } else {
      return (
        <img
          src={url}
          alt={title}
          className="h-48 w-auto rounded-md object-cover"
        />
      );
    }
  };

  // Helper function to render modal content
  const renderModalContent = (url, documentType) => {
    if (isPDF(documentType)) {
      return (
        <div className="flex max-h-[90vh] w-[90vw] flex-col items-center rounded-lg bg-white p-4">
          <iframe
            src={`${url}#view=FitH`}
            title="PDF Preview"
            className="h-full w-full min-w-[600px]"
          />
        </div>
      );
    } else {
      return (
        <img
          src={url}
          alt="Full Preview"
          className="max-h-[90vh] max-w-[90vw] rounded-md"
        />
      );
    }
  };

  return (
    <>
      <div className="scrollbar-track-transparent overflow-x-auto pb-2 scrollbar-thin scrollbar-thumb-[#1a1a1a]">
        <div className="flex w-max gap-5">
          {/* Aadhar Front */}
          {aadharFront && (
            <div
              className="relative flex-shrink-0 cursor-pointer"
              onClick={() =>
                setSelectedItem({
                  url: aadharFront,
                  type: documentTypes.aadharFront,
                  title: "Aadhar Card Front",
                })
              }
            >
              {renderPreviewItem(
                aadharFront,
                documentTypes.aadharFront,
                "Aadhar Card Front"
              )}
              <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-lg font-semibold text-white opacity-0 transition-opacity duration-150 hover:opacity-100">
                Aadhar Card Front
              </span>
            </div>
          )}

          {/* Aadhar Back */}
          {aadharBack && (
            <div
              className="relative flex-shrink-0 cursor-pointer"
              onClick={() =>
                setSelectedItem({
                  url: aadharBack,
                  type: documentTypes.aadharBack,
                  title: "Aadhar Card Back",
                })
              }
            >
              {renderPreviewItem(
                aadharBack,
                documentTypes.aadharBack,
                "Aadhar Card Back"
              )}
              <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-lg font-semibold text-white opacity-0 transition-opacity duration-150 hover:opacity-100">
                Aadhar Card Back
              </span>
            </div>
          )}

          {/* PAN Card */}
          {panCard && (
            <div
              className="relative flex-shrink-0 cursor-pointer"
              onClick={() =>
                setSelectedItem({
                  url: panCard,
                  type: documentTypes.panCard,
                  title: "PAN Card",
                })
              }
            >
              {renderPreviewItem(panCard, documentTypes.panCard, "PAN Card")}
              <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-lg font-semibold text-white opacity-0 transition-opacity duration-150 hover:opacity-100">
                PAN Card
              </span>
            </div>
          )}
        </div>
      </div>

      {/* Modal for full preview */}
      {selectedItem && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-[#000000cd] bg-opacity-70 p-3"
          onClick={() => setSelectedItem(null)}
        >
          <div onClick={(e) => e.stopPropagation()}>
            {renderModalContent(selectedItem.url, selectedItem.type)}
          </div>
        </div>
      )}
    </>
  );
};

export default KYCPreviewSlider;
