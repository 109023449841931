import { AiOutlineTwitter } from "react-icons/ai";
import { FaGithub, FaBitcoin, FaInstagram } from "react-icons/fa";
import { RiDiscordFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import Logo from "./../../../assets/img/landings/Vector.png";
import yt from "../../../assets/img/landings/yt.png";
import { useNavigate } from "react-router-dom";
const socialIcons = [
  {
    icon: "AiOutlineTwitter",
    link: "https://twitter.com/Aadi_Jain26",
  },
  {
    icon: "FaBitcoin",
    link: "https://bitcoin.org",
  },
  {
    icon: "FaInstagram",
    link: "https://www.instagram.com/titantechnologies.in/",
  },
];

const links = [
  [
    "MarketPlace",
    "About us",
    "Our team",
    "Team Details",
    "Error 404",
    "Cart Page",
  ],
  [
    "Company",
    "Wallet",
    "Create Page",
    "Login Page",
    "Registration",
    "Checkout",
  ],
  ["Services", "Contact Us", "Shop page", "Shop Details", "Collectors"],
];

export default function FooterSection() {
  const navigate = useNavigate();
  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickScrollplan = (id) => {
    navigate(`/#${id}`);
  };

  return (
    <footer class="bg-[#080808]-900 w-full">
      <div class=" mx-auto px-4 pt-16 pb-6 sm:px-6 lg:px-8 lg:pt-24">
        <div class="grid grid-cols-1 gap-8 lg:grid-cols-3">
          <div>
            <div class="flex justify-center text-teal-300 sm:justify-start">
              <img src={Logo} />
            </div>

            <p class="mx-auto mt-6 max-w-md text-center leading-relaxed text-gray-400 sm:mx-0 sm:max-w-xs sm:text-left">
              Titan Technologies employs advanced security measures, including
              multi-factor authentication, encrypted communications, and
              rigorous compliance protocols, to protect client investments and
              data integrity.
            </p>

            <ul class="mt-8 flex justify-center gap-6 sm:justify-start md:gap-8">
              <li>
                <a
                  href="https://www.youtube.com"
                  rel="noopener noreferrer"
                  target="_blank"
                  // class="text-[#F587A9]-500 transition hover:text-#A552DB-500/75"
                >
                  <span class="sr-only">Facebook</span>
                  <img className="h-[21px]" src={yt} alt="yt logo" />
                </a>
              </li>

              <li>
                <a
                  href="https://www.instagram.com/titantechnologies.in?igsh=dmJ5dXcxeWQ1OHlm&utm_source=qr"
                  rel="noopener noreferrer"
                  target="_blank"
                  // class="text-teal-500 transition hover:text-teal-500/75"
                >
                  <span class="sr-only">Instagram</span>
                  <svg
                    class="h-6 w-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
              </li>

              <li>
                <a
                  href="https://x.com/aadi_jain26?s=21"
                  rel="noopener noreferrer"
                  target="_blank"
                  // class="text-teal-500 transition hover:text-teal-500/75"
                >
                  <span class="sr-only">Twitter</span>
                  <svg
                    class="h-6 w-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>

          <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3 lg:col-span-2">
            <div class="text-center sm:text-left">
              <p class="text-lg font-medium text-white">Our Services</p>

              <nav class="mt-8">
                {/* <ul class="space-y-4 text-sm">
                  <li>
                    <a
                      class="text-white transition hover:text-white/75"
                      href="/"
                    >
                      Web Development
                    </a>
                  </li>

                  <li>
                    <a
                      class="text-white transition hover:text-white/75"
                      href="/"
                    >
                      Web Design
                    </a>
                  </li>

                  <li>
                    <a
                      class="text-white transition hover:text-white/75"
                      href="/"
                    >
                      Marketing
                    </a>
                  </li>

                  <li>
                    <a
                      class="text-white transition hover:text-white/75"
                      href="/"
                    >
                      Google Ads
                    </a>
                  </li>
                </ul> */}
                <ul class="space-y-4 text-sm">
                  <li>
                    <Link
                      class="text-white transition hover:text-white/75"
                      to="/plans"
                    >
                      Investment plans
                    </Link>
                  </li>

                  <li>
                    <a
                      class="text-white transition hover:text-white/75"
                      onClick={() => {
                        handleClickScrollplan("contact-us");
                      }}
                    >
                      Custom Investments Plans
                    </a>
                  </li>

                  <li>
                    <Link
                      class="text-white transition hover:text-white/75"
                      // href="#faq"
                      onClick={() => {
                        handleClickScroll("Testimonials");
                      }}
                    >
                      Testimonials
                    </Link>
                  </li>

                  <li>
                    <Link
                      class="text-white transition hover:text-white/75"
                      onClick={() => {
                        handleClickScroll("calculator");
                      }}
                    >
                      Calculate returns
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>

            <div class="text-center sm:text-left">
              <p class="text-lg font-medium text-white">Helpful Links</p>

              <nav class="mt-8">
                <ul class="space-y-4 text-sm">
                  <li>
                    <a
                      class="text-white transition hover:text-white/75"
                      // href="#faq"
                      onClick={() => {
                        handleClickScrollplan("faq");
                      }}
                    >
                      FAQs
                    </a>
                  </li>
                  <li>
                    <a
                      class="text-white transition hover:text-white/75"
                      href="mailto:help@titantechnologies.in"
                    >
                      Support
                    </a>
                  </li>
                  <li>
                    <a
                      class="group flex justify-center gap-1.5 sm:justify-start"
                      href="https://wa.me/919329104725"
                    >
                      <span class="text-white transition group-hover:text-white/75">
                        Live Chat
                      </span>

                      <span class="relative -mr-2 flex h-2 w-2">
                        <span class="absolute inline-flex h-full w-full animate-ping rounded-full bg-teal-400 opacity-75"></span>
                        <span class="relative inline-flex h-2 w-2 rounded-full bg-teal-500"></span>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="
                    /term-and-conditions"
                    >
                      Terms and Condition
                    </a>
                  </li>
                </ul>
              </nav>
            </div>

            <div class="text-center sm:text-left">
              <p class="text-lg font-medium text-white">Contact Us</p>

              <ul class="mt-8 space-y-4 text-sm">
                <li>
                  <a
                    class="group flex items-center justify-center gap-1.5 sm:justify-start"
                    href="/"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 shrink-0 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>

                    <span class="text-white transition group-hover:text-white/75">
                      titantechnologies@titantechnologies.in
                    </span>
                  </a>
                </li>

                <li>
                  <a
                    class="group flex items-center justify-center gap-1.5 sm:justify-start"
                    href="/"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 shrink-0 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      />
                    </svg>

                    <span class="text-white transition group-hover:text-white/75">
                      +91 9329104725
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="mt-12 border-t border-gray-800 pt-6">
          <div class="text-center sm:flex sm:justify-between sm:text-left">
            <p class="text-sm text-gray-400">
              <span class="block sm:inline">All rights reserved.</span>
            </p>

            <p class="mt-4 text-sm text-gray-500 sm:order-first sm:mt-0">
              &copy; 2024 Titan Technologies
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
